const state = () => ({
    application_version: ''
})

const mutations = {
    setApplicationVersion(state, applicationVersion: string): void {
        state.application_version = applicationVersion
    },
}

const actions = {
    async setApplicationVersion({ commit }, applicationVersion: string): Promise<void> {
        await commit('setApplicationVersion', applicationVersion)
    },
}

const getters = {
    application_version: (state) => state.application_version
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
