import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"revoke-device-traited-data-dialog-component"}},[_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.is_revoke_device_traited_data_dialog_open),callback:function ($$v) {_vm.is_revoke_device_traited_data_dialog_open=$$v},expression:"is_revoke_device_traited_data_dialog_open"}},[(_vm.is_revoke_device_traited_data_dialog_open)?_c(VCard,[_c(VCardTitle,{staticClass:"card-title error--text"},[_vm._v(" "+_vm._s(_vm.$t('revoke_device_traited_data_dialog_component.delete_device_message'))+" ")]),_c(VCardText,{staticClass:"mt-4 pb-0"},[_vm._v(" "+_vm._s(_vm.$t('revoke_device_traited_data_dialog_component.will_delete_device_message_from', { date: _vm.device_traited_data.date_releve | _vm.moment('DD/MM/Y HH:mm:ss'), }))+" ")]),_c(VContainer,{staticClass:"mt-4"},[_c(VBtn,{attrs:{"loading":_vm.deleting_device_traited_data,"block":"","color":"error","large":""},on:{"click":function($event){return _vm.putRevokeDeviceTraitedData()}}},[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.is_revoke_device_traited_data_dialog_open = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }