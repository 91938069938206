<template>
    <div id="edit-equipment">
        <v-container>
            <Header :text="$t('edit_equipment.edit_equipment_configuration')" :title="$t('edit_equipment.edit_equipment')" />

            <div>
                <device-input
                    ref="DeviceInputToReturn"
                    :label="$t('edit_equipment.device_number_to_edit')"
                    :type="type_edit"
                    class="mt-3"
                    v-on:getDeviceAsset="getDeviceAsset"
                    v-on:reset="reset"
                    v-on:resetLocal="reset"
                />

                <v-expand-transition>
                    <div v-if="selected_device !== ''" class="mt-3">
                        <template v-if="!refreshing">
                            <template v-if="asset_data.asset.id_module">
                                <edit-asset-of-asset-module-form-component
                                    ref="EditAssetOfAssetModuleForm"
                                    v-on:refresh:form="refreshForm()"
                                />
                            </template>

                            <template v-else>
                                <default-equipment-form-component
                                    ref="DefaultEquipmentForm"
                                    :device_number="selected_device"
                                    v-on:refresh:form="refreshForm()"
                                />
                            </template>
                        </template>

                        <template v-else>
                            <v-skeleton-loader type="image" />
                        </template>
                    </div>
                </v-expand-transition>
            </div>
        </v-container>
    </div>
</template>

<script>
import Header from '../../components/Header'
import DeviceInput from '../../components/DeviceInput'
import DefaultEquipmentFormComponent from '@/components/DefaultEquipmentFormComponent.vue'
import EditAssetOfAssetModuleFormComponent from '@/components/EditAssetOfAssetModuleFormComponent.vue'

export default {
    name: 'EditEquipment',
    components: {
        EditAssetOfAssetModuleFormComponent,
        DefaultEquipmentFormComponent,
        Header,
        DeviceInput,
    },
    data() {
        return {
            selected_device: '',
            asset_data: [],
            refreshing: false,
        }
    },
    methods: {
        getDeviceAsset(data, value) {
            this.asset_data = data
            this.selected_device = value
            this.refreshing = false

            this.$nextTick(() => {
                if (this.asset_data.asset.id_module) {
                    this.$refs.EditAssetOfAssetModuleForm.receiveDataFromDeviceInput(data, value)
                } else {
                    this.$refs.DefaultEquipmentForm.getDeviceAsset(data, value)
                }
            })
        },

        refreshForm() {
            this.refreshing = true
            this.$refs.DeviceInputToReturn.getDeviceAsset()
        },

        reset() {
            this.asset_data = []
            this.selected_device = ''
            this.selected_existing_address = ''
            this.selected_google_address = ''
            this.selected_brand_reference = ''
            this.selected_model_reference = ''
        },
    },
}
</script>
