import Vue from 'vue'
import Vuetify from 'vuetify/dist/vuetify.min'
import '@mdi/font/css/materialdesignicons.css'
import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'
import de from 'vuetify/lib/locale/de'

Vue.use(Vuetify)

function getLanguageToTranslate() {
    if (navigator.language.includes('en')) {
        return 'en'
    } else if (navigator.language.includes('de')) {
        return 'de'
    }

    return 'fr'
}

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: {fr, en, de},
        current: getLanguageToTranslate(),
    },
}
// @ts-ignore
export default new Vuetify(opts)
