import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"edit-asset-localisation-dialog-component"}},[_c(VDialog,{attrs:{"width":"800"},model:{value:(_vm.is_edit_asset_localisation_dialog_open),callback:function ($$v) {_vm.is_edit_asset_localisation_dialog_open=$$v},expression:"is_edit_asset_localisation_dialog_open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation'))+" ")]),_c(VCardText,[_c('map-coordinate-component',{attrs:{"coordinate":{ latitude: _vm.asset.latitude, longitude: _vm.asset.longitude }},on:{"change:coordinate":_vm.handleCoordinateChange}}),_c('div',{staticClass:"mb-4 text-center"},[(!_vm.geolocation_activate)?_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('edit_equipment.check_geolocation_parameter'))+" ")]),_c(VIcon,{attrs:{"color":"error","dense":""},on:{"click":_vm.openGeolocationProcedureDialog}},[_vm._v(" mdi-help-circle ")])],1):_vm._e()]),_c(VBtn,{staticClass:"validation-button",attrs:{"loading":_vm.editing_asset_localisation,"block":"","large":""},on:{"click":function($event){$event.preventDefault();return _vm.putAssetLocalization()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.is_edit_asset_localisation_dialog_open = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1),_c('geolocation-activation-procedure-dialog-component',{ref:"GeolocationActivationProcedureDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }