<template>
    <v-container id="dashboard">
        <div class="text-right ma-3 mb-10">
            <span class="mr-1 font-weight-medium username">
                {{ $t('dashboard.hello', { user: $auth.user().first_name || $auth.user().username }) }}
            </span>
            <v-icon color="blue" large @click="$router.push('profile')">mdi-account-circle</v-icon>
        </div>

        <div class="title-dashboard font-weight-bold text-uppercase mb-4 ml-3">
            {{ $t('dashboard.what_would_you_do') }}
        </div>

        <div class="mb-8">
            <dashboard-card
                icon="mdi-wifi-settings"
                link="check_network"
                :text="$t('dashboard.view_message_from_sensor')"
                :title="$t('dashboard.check_device_data')"
            />

            <v-row class="mt-2">
                <v-col cols="6">
                    <dashboard-card
                        icon="mdi-cart-plus"
                        link="order_devices"
                        :text="$t('dashboard.associate_device_to_professional')"
                        :title="$t('dashboard.associate_devices')"
                        size="l"
                    />
                </v-col>

                <v-col cols="6">
                    <dashboard-card
                        icon="mdi-download-network-outline"
                        link="install_device"
                        :text="$t('dashboard.redirect_installation_tunnel')"
                        :title="$t('dashboard.install_device')"
                    />
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="6">
                    <dashboard-card
                        icon="mdi-cart-arrow-up"
                        link="return_devices"
                        :text="$t('dashboard.return_or_exchange_device')"
                        :title="$t('dashboard.sav_devices')"
                    />
                </v-col>

                <v-col cols="6">
                    <dashboard-card
                        icon="mdi-pencil-outline"
                        link="edit_equipment"
                        :text="$t('dashboard.edit_equipment_configuration')"
                        :title="$t('dashboard.edit_equipment')"
                    />
                </v-col>
            </v-row>

            <div class="title-dashboard font-weight-bold mb-4 mt-8 ml-3 text-uppercase">
                {{ $t('dashboard.other_usefull_links') }}
            </div>

            <v-row>
                <v-col cols="12">
                    <h4>{{ $t('dashboard.general') }}</h4>
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-qrcode"
                        link="qr_code"
                        :text="$t('dashboard.manage_qrcode')"
                        :title="$t('dashboard.link_qrcode_to_urls')"
                        size="m"
                    />
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-link-variant"
                        link="send_downlink"
                        :text="$t('dashboard.reprogramming_device')"
                        :title="$t('dashboard.send_downlink')"
                        size="m"
                    />
                </v-col>

                <v-col cols="12">
                    <v-divider class="w-90"/>
                </v-col>

                <v-col cols="12">
                    <h4>{{ $t('dashboard.stock') }}</h4>
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-chart-bar-stacked"
                        link="manage_stock"
                        :text="$t('dashboard.save_sensors_battery')"
                        :title="$t('dashboard.device_in_stock_emitting')"
                        :append_inner="`${total_devices_in_stock_emitting}`"
                        size="m"
                    />
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-archive-arrow-down"
                        link="sensor_in_stock"
                        :title="$t('dashboard.all_sensor_in_stock')"
                        :text="$t('dashboard.sensor_in_stock')"
                        :append_inner="`${total_admin_devices}`"
                        append_inner_color="secondary"
                        size="m"
                    />
                </v-col>

                <v-col cols="12">
                    <v-divider class="w-90"/>
                </v-col>

                <v-col cols="12">
                    <h4>{{ $t('dashboard.network') }}</h4>
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-access-point-network"
                        link="link_network_device"
                        :title="$t('dashboard.add_network_asset')"
                        :text="$t('dashboard.link_gateway_repeater_pnes')"
                        size="m"
                    />
                </v-col>

                <v-col cols="12">
                    <dashboard-card
                        icon="mdi-cloud-arrow-down-outline"
                        link="send_sms"
                        :text="$t('sens_sms_page.sens_sms')"
                        :title="$t('dashboard.send_sms_config')"
                        size="m"
                    />
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import DashboardCard from '../../components/DashboardCard'
import DeviceRepository from '@/repositories/DeviceRepository'
export default {
    components: { DashboardCard },
    data() {
        return {
            total_devices_in_stock_emitting: null,
            total_admin_devices: null
        }
    },
    created() {
        this.getDeviceByIdUserAdminDatatable()
        this.getAllAdminDevices()
    },
    methods: {
        getDeviceByIdUserAdminDatatable() {
            DeviceRepository.getDeviceByIdUserAdminAndIsInStockDatatable(1, '').then(response => {
                this.total_devices_in_stock_emitting = response.data.data.total_rows
            }).catch(error => {
                this.manageError(error)
            });
        },
        getAllAdminDevices(){
            DeviceRepository.getAllDeviceByIdUserAdminAndIsInStockDatatable('', '', 0, 1).then(response => {
                this.total_admin_devices = response.data.data.total_rows
            }).catch(error => {
                this.manageError(error)
            });
        }
    }
}
</script>

<style>
.title-dashboard {
    color: #4c545f;
    font-size: 28px;
    line-height: 30px;
}

.username {
    color: #788a9e;
}
</style>
