<template>
    <div id="device-referential-kpi-component">
        <v-card class="shadow-md">
            <v-card-title>
                <v-row align="center">
                    <v-col cols="3">
                        <v-img :src="device_referential_kpi.picture" class="ma-auto" contain max-height="50" max-width="50" />
                    </v-col>
                    <v-col cols="9">
                        <h5>{{ device_referential_kpi.name }}</h5>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider />

            <v-card-text class="pt-4 pb-4">
                <v-row dense>
                    <v-col class="text-center"> V: {{ device_referential_kpi.hard_version }}</v-col>
                </v-row>

                <v-row class="text-center pb-3">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="pointer" size="x-large" v-bind="attrs" v-on="on"> mdi-archive-arrow-down </v-icon>
                                    </template>
                                    {{ $t('manage_stock.in_stock') }}
                                </v-tooltip>
                            </v-col>

                            <v-col cols="3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="pointer" size="x-large" v-bind="attrs" v-on="on">mdi-turbine </v-icon>
                                    </template>
                                    {{ $t('manage_stock.in_prototyping') }}
                                </v-tooltip>
                            </v-col>

                            <v-col cols="3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="pointer" size="x-large" v-bind="attrs" v-on="on"> mdi-arrow-u-down-left </v-icon>
                                    </template>
                                    {{ $t('manage_stock.in_return') }}
                                </v-tooltip>
                            </v-col>

                            <v-col cols="3">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon class="pointer" size="x-large" v-bind="attrs" v-on="on">
                                            mdi-transit-connection-horizontal
                                        </v-icon>
                                    </template>
                                    {{ $t('manage_stock.in_transition') }}
                                </v-tooltip>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                {{ device_referential_kpi.total_devices_in_stock }}
                            </v-col>

                            <v-col cols="3">
                                {{ device_referential_kpi.total_devices_prototyping }}
                            </v-col>

                            <v-col cols="3">
                                {{ device_referential_kpi.total_devices_in_return }}
                            </v-col>

                            <v-col cols="3">
                                {{ device_referential_kpi.total_devices_in_transition }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-divider />

                <v-row class="text-center mt-1">
                    <v-col cols="3">
                        <v-img :src="device_referential_kpi.picture" class="align-start" contain max-height="25" />
                    </v-col>

                    <v-divider vertical />
                    <v-col cols="3">
                        <v-img :src="device_referential_kpi.network_icon" class="align-start" contain max-height="25" />
                    </v-col>

                    <v-divider vertical />
                    <v-col cols="6">
                        <h4>Total: {{ device_referential_kpi.total_devices }}</h4>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { IDeviceReferentialKpi } from '@/models/IDeviceReferentialKpi'

export default {
    name: 'DeviceReferentialKpiComponent',
    props: {
        device_referential_kpi: {
            type: IDeviceReferentialKpi,
            require: true,
        },
    },
}
</script>
