<template>
    <div id="geolocation-activation-procedure-dialog-component">
        <v-dialog v-model="is_geolocation_activation_procedure_dialog_open" max-width="600px">
            <v-card>
                <v-card-title>
                    {{ $t('geolocation_activation_procedure_dialog_component.geolocation_activation_procedure') }}
                </v-card-title>

                <v-card-text>
                    <v-alert color="warning" dense text class="font-12">
                        {{ $t('geolocation_activation_procedure_dialog_component.step_may_vary_depending_browser_device_using') }}
                    </v-alert>

                    <div class="mb-4 font-12">
                        <p class="text-uppercase mb-1 font-weight-bold">
                            1. {{ $t('geolocation_activation_procedure_dialog_component.access_parameters') }}
                        </p>

                        <div class="pl-4">
                            <div class="mb-2">
                                <p class="mb-0 font-weight-bold">
                                    {{ $t('geolocation_activation_procedure_dialog_component.on_browser') }}
                                </p>
                                {{ $t('geolocation_activation_procedure_dialog_component.click_icon_left_url_then_site_settings') }}
                                <v-img contain height="80" src="../assets/images/access_site_settings.png" />
                            </div>

                            <div>
                                <p class="mb-0 font-weight-bold">
                                    {{ $t('geolocation_activation_procedure_dialog_component.on_mobile') }}
                                </p>
                                <ul>
                                    <li>
                                        <strong>{{$t('geolocation_activation_procedure_dialog_component.android')}}</strong> : {{ $t('geolocation_activation_procedure_dialog_component.quit_application_hold_down_application_icon')}}
                                    </li>
                                    <li>
                                        <strong>{{$t('geolocation_activation_procedure_dialog_component.ios')}}</strong> : {{$t('geolocation_activation_procedure_dialog_component.relaunch_application')}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4 font-12">
                        <p class="text-uppercase mb-1 font-weight-bold">2. {{$t('geolocation_activation_procedure_dialog_component.authorize_geolocation')}}</p>
                        <div class="pl-4">{{ $t('geolocation_activation_procedure_dialog_component.in_settings_authorize_location')}}</div>
                    </div>

                    <v-btn block class="validation-button" color="primary" @click="is_geolocation_activation_procedure_dialog_open = false">
                        {{ $t('global.close') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'GeolocationActivationProcedureDialogComponent',
    data() {
        return {
            is_geolocation_activation_procedure_dialog_open: false,
        }
    },
    methods: {
        openGeolocationActivationProcedureDialog() {
            this.is_geolocation_activation_procedure_dialog_open = true
        },
    },
}
</script>
