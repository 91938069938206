<template>
    <div id="exchange-asset-domicile-dialog-component">
        <v-dialog v-model="is_exchange_asset_domicile_dialog_open" width="600">
            <v-card>
                <v-card-title> {{ $t('exchange_asset_domicile_dialog_component.associate_new_site') }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-select
                            v-model="domicile"
                            :item-text="
                                (item) => {
                                    return displayHumanSiteAddress(item)
                                }
                            "
                            :items="domiciles"
                            :label="$t('exchange_asset_domicile_dialog_component.client_site')"
                            :loading="fetching_domiciles"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :no-data-text="$t('exchange_asset_domicile_dialog_component.no_client_site')"
                            :rules="[(v) => !!v.id_domicile || this.$t('generic_form_component.required_fields')]"
                            filled
                            item-value="id_domicile"
                            return-object
                            @change="fetchClientDomiciles"
                        >
                            <template v-slot:prepend-item>
                                <v-btn block text @click="openAddClientDomicileDialog">
                                    <v-icon>mdi-plus</v-icon>
                                    {{ $t('global.add') }}
                                </v-btn>
                            </template>
                        </v-select>

                        <v-btn
                            :loading="associating_asset_to_domicile"
                            block
                            class="validation-button"
                            large
                            @click.prevent="putAssociateAssetToDomicile()"
                        >
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="is_exchange_asset_domicile_dialog_open = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <add-edit-client-domicile-dialog-component ref="addEditClientDomicileDialog" v-on:added:domicile="fetchClientDomiciles" />
    </div>
</template>

<script>
import ClientRepository from '@/repositories/ClientRepository'
import AssetRepository from '@/repositories/AssetRepository'
import AddEditClientDomicileDialogComponent from '@/components/site/AddEditClientDomicileDialogComponent.vue'

export default {
    name: 'exchange-asset-domicile-dialog-component',
    components: { AddEditClientDomicileDialogComponent },
    data() {
        return {
            asset: {},
            domicile: {},
            domiciles: [],

            is_exchange_asset_domicile_dialog_open: false,
            fetching_domiciles: true,
            associating_asset_to_domicile: false,
        }
    },
    methods: {
        openExchangeAssetDomicileDialog(asset) {
            this.asset = asset
            this.is_exchange_asset_domicile_dialog_open = true
            this.fetchClientDomiciles()
        },

        openAddClientDomicileDialog() {
            this.$refs.addEditClientDomicileDialog.openAddClientDomicileDialog(this.asset.client)
        },

        fetchClientDomiciles() {
            this.fetching_domiciles = true
            ClientRepository.getClientDomiciles(this.asset.client.id_client)
                .then((success) => {
                    this.domiciles = success.data.data.filter((domicile) => domicile.id_domicile !== this.asset.domicile.id_domicile)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_domiciles = false
                })
        },

        async putAssociateAssetToDomicile() {
            const valid = await this.$refs.form.validate()
            if (valid) {
                this.associating_asset_to_domicile = true
                AssetRepository.putAssociateAssetToDomicile(this.asset.id_asset, this.domicile.id_domicile)
                    .then(() => {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'success',
                            text: this.$t('exchange_asset_domicile_dialog_component.equipment_associated_with_another_site'),
                        })
                        this.is_exchange_asset_domicile_dialog_open = false
                        this.$emit('refresh:form')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.associating_asset_to_domicile = false
                    })
            }
        },
    },
}
</script>
