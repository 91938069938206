import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"color-picker-component"}},[_c('div',{on:{"click":function($event){_vm.dialog_pick_color = true}}},[_c(VTextField,{staticClass:"text-white",attrs:{"background-color":_vm.color,"label":_vm.$t('color_picker_component.color'),"filled":"","color":"white"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog_pick_color),callback:function ($$v) {_vm.dialog_pick_color=$$v},expression:"dialog_pick_color"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('color_picker_component.content_color'))+" ")]),_c(VCardText,{staticClass:"pt-4 pb-0"},[_c(VColorPicker,{staticClass:"mx-auto pb-0",attrs:{"elevation":"0","hide-inputs":""},model:{value:(_vm.temporary_color),callback:function ($$v) {_vm.temporary_color=$$v},expression:"temporary_color"}})],1),_c(VContainer,{staticClass:"px-6"},[_c(VBtn,{staticClass:"validation-button",attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmLiquidChoose()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.dialog_pick_color = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }