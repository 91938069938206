<template>
    <div id="qr-code">
        <qr-code-scanner-component v-if="scanner" v-on:cancel="scanner = false" v-on:scanned="getDataScanner" />

        <v-container>
            <Header :text="$t('qr_code.associate_existing_qr_codes_urls')" :title="$t('qr_code.qr_code_management')" />

            <p class="mt-4">{{ $t('qr_code.select_link_wish_redirect_qr_code') }}</p>

            <v-select
                v-model="link"
                :items="links"
                :label="$t('qr_code.choose_link')"
                :menu-props="{ bottom: true, offsetY: true }"
                hide-details
                item-text="value"
                item-value="key"
                outlined
                @change="focusDeviceInput"
            />

            <v-expand-transition>
                <div v-if="link">
                    <device-input
                        ref="deviceToBind"
                        :label="$t('qr_code.device_number_to_edit')"
                        :type="type_details"
                        class="mt-4"
                        v-on:getDeviceDetails="manageDevice"
                        v-on:reset="reset"
                        v-on:resetLocal="reset"
                    />

                    <v-expand-transition>
                        <div v-if="has_module && !loading_asset" class="mt-3">
                            <v-list v-if="show_details" class="my-4 px-0" dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <strong class="mr-1">{{ $t('qr_code.desk') }}</strong>
                                        {{ professional.distributor_name }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <strong class="mr-1">{{ $t('qr_code.client') }}</strong>
                                        {{ client ? client.company_name : $t('qr_code.no_client_associate') }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <strong class="mr-1">{{ $t('qr_code.asset_name') }}</strong>
                                        {{ asset.name }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <strong class="mr-1">{{ $t('qr_code.address') }}</strong>{{ complete_address }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <strong class="mr-1">{{ $t('qr_code.address_name') }}</strong>
                                        {{ asset.domicile ? asset.domicile.address_name : $t('qr_code.no_site_associate') }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            <h2>
                                {{ $t('qr_code.link_to_qr_code') }}
                            </h2>

                            <p class="mt-4">
                                {{ $t('qr_code.scan_printed_physical_qr_code') }}
                            </p>
                            <v-text-field
                                ref="qrCode"
                                v-model="qr_code"
                                :label="$t('qr_code.qr_code_link')"
                                :loading="loading_qr_code"
                                outlined
                                @change="getQrCodeByUrl"
                            >
                                <template v-slot:append>
                                    <v-icon append color="blue" @click="openScanner()">mdi-qrcode-scan</v-icon>
                                </template>
                            </v-text-field>

                            <v-btn
                                ref="btnBindQrCode"
                                :class="invalid ? '' : 'validation-button'"
                                :disabled="invalid"
                                block
                                class="mt-4"
                                large
                                @click="dialog_bind_qr_code_to_device = true"
                            >
                                {{ $t('qr_code.link_device_to_qr_code') }}
                            </v-btn>
                        </div>

                        <div v-if="!has_module && is_load" class="mt-3">
                            <p class="error--text font-weight-bold">
                                {{ $t('qr_code.professional_not_have_qr_code_module') }}
                            </p>
                        </div>
                    </v-expand-transition>
                </div>
            </v-expand-transition>
        </v-container>

        <v-dialog v-model="dialog_bind_qr_code_to_device" width="600">
            <v-card>
                <v-card-title>{{ $t('qr_code.link_qr_code_to_device') }}</v-card-title>

                <v-card-text>
                    <v-list v-if="asset" class="mb-4" dense>
                        <v-list-item class="px-0">
                            <v-list-item-content>
                                <strong class="mr-1">{{ $t('qr_code.desk') }}</strong>
                                {{ professional.distributor_name }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                            <v-list-item-content>
                                <strong class="mr-1">{{ $t('qr_code.client') }}</strong>
                                {{ client ? client.company_name : $t('qr_code.no_client_associate') }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                            <v-list-item-content >
                                <strong class="mr-1">{{ $t('qr_code.asset_name') }}</strong>
                                {{ asset.name }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                            <v-list-item-content>
                                <strong class="mr-1">{{ $t('qr_code.address') }}</strong>
                                {{ complete_address }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="px-0">
                            <v-list-item-content>
                                <strong class="mr-1">{{ $t('qr_code.address_name') }}</strong>
                                {{ asset.domicile ? asset.domicile.address_name : $t('qr_code.no_site_associate') }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-btn :loading="loading_bind_qr_code_to_device" block large class="validation-button"
                           @click.prevent="bindQrCode()"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="dialog_bind_qr_code_to_device = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Header from '../../components/Header'
import DeviceInput from '../../components/DeviceInput'
import ProfessionalRepository from '../../repositories/ProfessionalRepository'
import QrCodeRepository from '../../repositories/QrCodeRepository'
import QrCodeScannerComponent from '@/components/QrCodeScannerComponent.vue'
import i18n from '../../plugins/i18n'
import AssetRepository from '@/repositories/AssetRepository'

export default {
    name: 'QrCode',
    components: { DeviceInput, Header, QrCodeScannerComponent },
    data() {
        return {
            device: null,
            has_module: false,
            is_load: false,
            links: [
                {
                    key: 'public',
                    value: this.$t('qr_code.link_to_public_page')
                }
            ],
            loading_asset: false,
            show_details: false,
            asset: null,
            client: null,
            professional: null,
            link: null,
            loading_qr_code: false,
            qr_code: '',
            id_qr_code: null,
            scanner: false,
            dialog_bind_qr_code_to_device: false,
            loading_bind_qr_code_to_device: false
        }
    },
    computed: {
        invalid: function() {
            return !(this.link !== null && this.device !== null && this.qr_code !== '' && this.id_qr_code !== null)
        },
        complete_address: function() {
            if(this.asset) {
                let result = this.$t('qr_code.no_site_associate');
                let domicile = this.asset.domicile;
                if(domicile) {
                    result = `${domicile.street_number} ${domicile.address} ${domicile.city} ${domicile.postal_code} ${domicile.country}`
                }

                return result
            }
        }
    },
    methods: {
        focusDeviceInput() {
            this.$nextTick(() => {
                this.$refs.deviceToBind.$refs.comboboxDevice.focus()
            })
        },

        manageDevice(data) {
            this.device = data
            this.checkIfProfessionalHasModuleQrCode()

        },

        checkIfProfessionalHasModuleQrCode() {
            this.$refs.deviceToBind.loading = true

            ProfessionalRepository.getCheckProfessionalHasModule(this.device.id_professional, 'FLSH')
                .then((success) => {
                    this.has_module = success.data.data
                    this.is_load = true

                    this.getAssetByDeviceNumber()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.$refs.deviceToBind.loading = false
                })
        },

        getAssetByDeviceNumber() {
            this.loading_asset = true

            AssetRepository.getAssetByDeviceNumber(this.device.device_number).then(result => {
                this.asset = result.data.data.asset;
                this.client = result.data.data.client;
                this.professional = result.data.data.professional;
                this.show_details = true
            }).catch(() => {
                this.show_details = false
            }).finally(() => {
                this.loading_asset = false
                this.$nextTick(() => {
                    if (this.has_module) {
                        this.$refs.qrCode.focus()
                    }
                })
            })
        },

        openScanner() {
            this.scanner = true
            this.hideKeyboard()
        },

        getDataScanner(value) {
            this.scanner = false
            this.qr_code = value
            this.getQrCodeByUrl()
        },

        getQrCodeByUrl() {
            this.loading_qr_code = true

            QrCodeRepository.getQrCodeByUid(this.qr_code.split('/').pop())
                .then((success) => {
                    this.$refs.qrCode.focus(false)
                    let color = 'success'
                    let text = this.$t('qr_code.qr_code_ready_to_link')

                    if (success.data.data.url_redirection) {
                        color = 'info'
                        text = this.$t('qr_code.qr_code_already_link_to_url')
                    }

                    this.id_qr_code = success.data.data.id_qr_code
                    this.$store.commit('snackbar/showSnackbar', { color: color, text: text })

                    this.$nextTick(() => {
                        this.$refs.btnBindQrCode.$el.focus()
                    })
                })
                .catch((error) => {
                    this.qr_code = ''

                    if (error.response.status === 404) {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'error',
                            text: i18n.t('qr_code.qr_code_not_found')
                        })
                    } else {
                        this.manageError(error)
                    }
                })
                .finally(() => {
                    this.loading_qr_code = false
                })
        },

        bindQrCode() {
            if (this.id_qr_code && this.checkUrl()) {
                QrCodeRepository.postBindQrCode(this.id_qr_code, this.device.id_device, this.link)
                    .then(() => {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'success',
                            text: this.$t('qr_code.linked_qr_code')
                        })
                        this.$refs.deviceToBind.resetGlobal()

                        this.$nextTick(() => {
                            this.$refs.deviceToBind.$refs.comboboxDevice.focus()
                        })
                    })
                    .catch((error) => {
                        this.manageError(error)
                    }).finally(() => {
                        this.dialog_bind_qr_code_to_device = false
                })
            }
        },

        checkUrl() {
            let result = false
            let url = this.qr_code.split('/api/v1/qr_code')

            if (this.isProdEnvironment()) {
                result = url[0] === 'https://api-pwa.quatre-factorielle.com'
            } else if (this.isPrpEnvironment()) {
                result = url[0] === 'https://prp.api.quatre-factorielle.com'
            }

            if (!result) {
                this.$store.commit('snackbar/showSnackbar', {
                    color: 'error',
                    text: i18n.t('qr_code.wrong_link')
                })
            }

            return result
        },

        reset() {
            this.device = null
            this.has_module = false
            this.asset = null
            this.client = null
            this.professional = null
            this.is_load = false
            this.qr_code = ''
            this.id_qr_code = null
        },

        hideKeyboard() {
            //this set timeout needed for case when hideKeyborad
            //is called inside of 'onfocus' event handler
            setTimeout(function() {
                //creating temp field
                var field = document.createElement('input')
                field.setAttribute('type', 'text')
                //hiding temp field from peoples eyes
                //-webkit-user-modify is nessesary for Android 4.x
                field.setAttribute(
                    'style',
                    'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;'
                )
                document.body.appendChild(field)

                //adding onfocus event handler for out temp field
                field.onfocus = function() {
                    //this timeout of 200ms is nessasary for Android 2.3.x
                    setTimeout(function() {
                        field.setAttribute('style', 'display:none;')
                        setTimeout(function() {
                            document.body.removeChild(field)
                            document.body.focus()
                        }, 14)
                    }, 200)
                }
                //focusing it
                field.focus()
            }, 50)
        },

        isPrpEnvironment() {
            return process.env.VUE_APP_API_URL === 'https://prp.api.quatre-factorielle.com/'
        },

        isProdEnvironment() {
            return process.env.VUE_APP_API_URL === 'https://api-pwa.quatre-factorielle.com/'
        },
    }
}
</script>
