<template>
    <div id="revoke-device-traited-data-dialog-component">
        <v-dialog v-model="is_revoke_device_traited_data_dialog_open" max-width="600">
            <v-card v-if="is_revoke_device_traited_data_dialog_open">
                <v-card-title class="card-title error--text">
                    {{ $t('revoke_device_traited_data_dialog_component.delete_device_message') }}
                </v-card-title>

                <v-card-text class="mt-4 pb-0">
                    {{
                        $t('revoke_device_traited_data_dialog_component.will_delete_device_message_from', {
                            date: device_traited_data.date_releve | moment('DD/MM/Y HH:mm:ss'),
                        })
                    }}
                </v-card-text>

                <v-container class="mt-4">
                    <v-btn :loading="deleting_device_traited_data" block color="error" large
                           @click="putRevokeDeviceTraitedData()">
                        {{ $t('global.delete') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text
                           @click="is_revoke_device_traited_data_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DeviceTraitedDataRepository from '@/repositories/DeviceTraitedDataRepository'
import moment from 'moment'

export default {
    name: 'RevokeDeviceTraitedDataDialogComponent',
    data() {
        return {
            is_revoke_device_traited_data_dialog_open: false,
            device_traited_data: null,
            deleting_device_traited_data: false,
        }
    },
    methods: {
        moment() {
            return moment
        },
        openRevokeDeviceTraitedDataDialog(new_device_traited_data) {
            this.is_revoke_device_traited_data_dialog_open = true
            this.device_traited_data = new_device_traited_data
        },
        putRevokeDeviceTraitedData() {
            this.deleting_device_traited_data = true
            DeviceTraitedDataRepository.putRevokeDeviceTraitedData(this.device_traited_data.id_devices_traited_data)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('revoke_device_traited_data_dialog_component.device_message_deleted'),
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.deleting_device_traited_data = false
                    this.is_revoke_device_traited_data_dialog_open = false
                })
        },
    },
}
</script>
