<template>
    <div id="edit-contact-on-site-component">
        <div class="d-flex justify-space-between align-center pointer" @click="openContactOnSiteForm">
            <p class="mb-0 font-weight-bold text-uppercase text-skin-medium">
                {{ $t('edit_contact_on_site_component.contact_on_site') }}
            </p>
            <v-icon> {{ !show_contact_on_site ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>

        <div class="mt-2 mb-4" style="width: 100%; background: #ccc; height: 1px" />

        <v-expand-transition>
            <div v-if="show_contact_on_site">
                <v-text-field v-model="contact_on_site.contact_last_name" :label="$t('edit_contact_on_site_component.last_name')" filled />
                <v-text-field v-model="contact_on_site.contact_first_name" :label="$t('edit_contact_on_site_component.first_name')" filled />
                <v-text-field
                    v-model="contact_on_site.contact_mail"
                    :label="$t('edit_contact_on_site_component.email')"
                    filled
                    type="email"
                />
                <v-text-field v-model="contact_on_site.contact_phone" :label="$t('edit_contact_on_site_component.phone')" filled type="tel" />
                <v-textarea v-model="contact_on_site.contact_note" :label="$t('edit_contact_on_site_component.note')" filled />
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    name: 'EditContactOnSiteComponent',
    data() {
        return {
            show_contact_on_site: false,
        }
    },
    props: {
        contact_on_site: {
            type: Object,
            required: true,
        }
    },
    methods: {
        openContactOnSiteForm() {
            this.show_contact_on_site = !this.show_contact_on_site
        },
    },
}
</script>
