<template>
    <v-container id="Login" class="fill-height text-xs-center justify-center">
        <form class="justify-center mt-auto mb-auto">
            <v-img
                class="justify-center text-center mb-10 ml-auto mr-auto"
                max-height="120"
                max-width="230"
                src="../../assets/images/HD_FOUR_DATA.png"
            />

            <p class="title-login text-center mb-2 text-uppercase">
                {{ $t('login.welcome_installer_area') }}
            </p>

            <p class="subtitle-login text-center font-14">
                {{ $t('login.continue_enter_email_password') }}
            </p>

            <div id="login-input">
                <v-text-field
                    v-model="email"
                    :label="$t('login.email')"
                    :rules="email_rules"
                    filled
                    prepend-inner-icon="mdi-at"
                    type="email"
                />

                <v-text-field
                    v-model="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('login.password')"
                    :rules="password_rules"
                    :type="show ? 'text' : 'password'"
                    filled
                    prepend-inner-icon="mdi-lock"
                    @click:append="show = !show"
                    @keypress.enter="login()"
                />
            </div>

            <div class="text-center mt-4">
                <v-btn
                    :class="invalid ? '' : 'validation-button'"
                    :disabled="invalid"
                    :loading="loading"
                    block
                    large
                    @click="login()"
                >
                    {{ $t('login.login') }}
                </v-btn>
            </div>
        </form>
    </v-container>
</template>

<script>
import UserClientRepository from '@/repositories/UserClientRepository'

export default {
    name: 'Login',
    created() {
        if (this.$route.query.token) {
            UserClientRepository.checkToken(this.$route.query.token).then((success) => {
                this.$auth.token(null, success.data.data)
                this.$auth.fetch({}).then(() => {
                    this.$router.push({name: 'dashboard'})
                })
            })
        }
    },
    data() {
        return {
            email: '',
            password: '',
            loading: false,
            show: false,

            email_rules: [
                (v) => !!v || this.$t('login.email_required'),
                (v) =>
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v,
                    ) || 'Email non valide',
            ],
            password_rules: [(v) => !!v || this.$t('login.password_required')],
        }
    },
    methods: {
        login() {
            this.loading = true
            this.$auth
                .login({
                    data: {
                        email: this.email,
                        password: this.password,
                    },
                    staySignedIn: true,
                })
                .then(() => {
                    this.$router.push({name: 'dashboard'})
                })
                .catch((error) => {
                    if (error.response !== undefined) {
                        error.response.data.translation_key = error.response.status === 401 ? 'wrong_credentials' : 'login_error'
                    }
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    computed: {
        invalid: function () {
            let re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(this.email) && this.password.length > 0)
        },
    },
}
</script>

<style>
#login-input .v-input__slot {
    background-color: white !important;
    border: white !important;
}

.title-login {
    color: #4c545f !important;
    font-weight: bold !important;
    font-size: 28px;
    line-height: 30px;
}

.subtitle-login {
    color: #4c545f !important;
}
</style>
