<template>
    <div id="manage-note-admin-device-widget-component">
        <widget-wrapper-component :title="$t('edit_equipment.device_admin_note', { device: device_number })" icon="mdi-remote">
            <template v-slot:default>
                <div
                    @click="
                        temporary_note_admin = note_admin
                        dialog_update_note_admin_device = true
                    "
                >
                    <v-textarea
                        v-model="note_admin"
                        :label="$t('edit_equipment.device_admin_note', { device: device_number })"
                        class="mt-1"
                        filled
                        hide-details
                        readonly
                        rows="3"
                    />
                </div>
            </template>
        </widget-wrapper-component>

        <v-dialog v-model="dialog_update_note_admin_device" max-width="600">
            <v-card>
                <v-card-title class="card-title">
                    {{ $t('edit_equipment.edit_device_admin_note', { device: device_number }) }}
                </v-card-title>

                <v-card-text class="mt-4 pb-0">
                    <v-textarea
                        v-model="temporary_note_admin"
                        :label="$t('edit_equipment.device_admin_note', { device: device_number })"
                        filled
                        rows="3"
                    />
                </v-card-text>

                <v-container class="px-6">
                    <v-btn
                        :loading="loading_update_note_admin_device"
                        block
                        class="validation-button"
                        color="primary"
                        large
                        @click.prevent="updateDeviceNote()"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="dialog_update_note_admin_device = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DeviceRepository from '@/repositories/DeviceRepository'
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'

export default {
    name: 'ManageNoteAdminDeviceWidgetComponent',
    components: { WidgetWrapperComponent },
    props: {
        device_number: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            dialog_update_note_admin_device: false,
            loading_update_note_admin_device: false,
            note_admin: '',
            temporary_note_admin: '',
        }
    },
    created() {
        this.getDeviceInformation()
    },
    methods: {
        getDeviceInformation() {
            DeviceRepository.getDeviceByDeviceNumber(this.device_number)
                .then((response) => {
                    this.note_admin = response.data.data.note_admin
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        updateDeviceNote() {
            this.loading_update_note_admin_device = true

            DeviceRepository.updateDevice(this.device_number, this.temporary_note_admin)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('edit_equipment.note_admin_updated'),
                    })
                    this.note_admin = this.temporary_note_admin
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dialog_update_note_admin_device = false
                    this.loading_update_note_admin_device = false
                    this.temporary_note_admin = ''
                })
        },
    },
}
</script>
