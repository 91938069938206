import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"device-referential-kpi-component"}},[_c(VCard,{staticClass:"shadow-md"},[_c(VCardTitle,[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"3"}},[_c(VImg,{staticClass:"ma-auto",attrs:{"src":_vm.device_referential_kpi.picture,"contain":"","max-height":"50","max-width":"50"}})],1),_c(VCol,{attrs:{"cols":"9"}},[_c('h5',[_vm._v(_vm._s(_vm.device_referential_kpi.name))])])],1)],1),_c(VDivider),_c(VCardText,{staticClass:"pt-4 pb-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"text-center"},[_vm._v(" V: "+_vm._s(_vm.device_referential_kpi.hard_version))])],1),_c(VRow,{staticClass:"text-center pb-3"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"pointer",attrs:{"size":"x-large"}},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-arrow-down ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('manage_stock.in_stock'))+" ")])],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"pointer",attrs:{"size":"x-large"}},'v-icon',attrs,false),on),[_vm._v("mdi-turbine ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('manage_stock.in_prototyping'))+" ")])],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"pointer",attrs:{"size":"x-large"}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-u-down-left ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('manage_stock.in_return'))+" ")])],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"pointer",attrs:{"size":"x-large"}},'v-icon',attrs,false),on),[_vm._v(" mdi-transit-connection-horizontal ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('manage_stock.in_transition'))+" ")])],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.device_referential_kpi.total_devices_in_stock)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.device_referential_kpi.total_devices_prototyping)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.device_referential_kpi.total_devices_in_return)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.device_referential_kpi.total_devices_in_transition)+" ")])],1)],1)],1),_c(VDivider),_c(VRow,{staticClass:"text-center mt-1"},[_c(VCol,{attrs:{"cols":"3"}},[_c(VImg,{staticClass:"align-start",attrs:{"src":_vm.device_referential_kpi.picture,"contain":"","max-height":"25"}})],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"3"}},[_c(VImg,{staticClass:"align-start",attrs:{"src":_vm.device_referential_kpi.network_icon,"contain":"","max-height":"25"}})],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Total: "+_vm._s(_vm.device_referential_kpi.total_devices))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }