import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { IMinimalistClient } from '@/models/IMinimalistClient'

const resource = '/api/v1/professional'

export default {
    getProfessional(): Promise<AxiosResponse<IServerResponse<Array<IProfessional>>>> {
        return axios.get(resource)
    },

    getCheckProfessionalHasModule(id_professional: number, moduleName: string): Promise<AxiosResponse<IServerResponse<boolean>>> {
        return axios.get(`${resource}/${id_professional}/module?designation=${moduleName}`)
    },

    getMinimalProfessionalClients(id_professional: number): Promise<AxiosResponse<IServerResponse<Array<IMinimalistClient>>>> {
        return axios.get(`${resource}/${id_professional}/clients/minimal`)
    },
}
