<template>
    <div id="list-check-network">
        <v-expand-transition>
            <div v-if="is_checking_network">
                <span class="font-weight-medium font-14">
                    <v-icon class="pr-2">mdi-upload-network</v-icon>
                    {{ $t('list_check_network.last_10_device_messages') }}
                </span>

                <div v-if="historical_traited_data.length > 0" class="font-14">
                    <v-list class="mt-2 px-2" dense rounded>
                        <div v-for="(network_data, index) in historical_traited_data" class="mt-3">
                            <!-- REVOKE DTD -->
                            <v-list-item>
                                <v-list-item-content />

                                <span class="error--text font-12 pointer" @click="openRevokeDeviceTraitedDataDialog(network_data)">
                                    {{ $t('list_check_network.delete_statement') }}
                                </span>
                            </v-list-item>

                            <!-- DATE RELEVE -->
                            <v-list-item>
                                <v-list-item-content class="font-weight-medium pa-0">
                                    {{ $t('list_check_network.last_statement') }}
                                </v-list-item-content>

                                <div class="text-end mb-4">
                                    <chips-last-statement :danger="345600" :date="network_data.due_date.date" :warning="172800" />
                                    <br />
                                    <span class="font-12">{{ network_data.due_date.date | moment('DD/MM/Y HH:mm:ss') }}</span>
                                </div>
                            </v-list-item>

                            <!-- ASSET METRICS -->
                            <v-list-group
                                v-if="headers.filter((item) => item.type === 'asset').length > 0"
                                :value="true"
                                prepend-icon="mdi-storage-tank-outline"
                            >
                                <template v-slot:activator>
                                    <v-list-item-content class="font-weight-medium pa-0">
                                        {{ $t('list_check_network.equipment_data') }}
                                    </v-list-item-content>
                                </template>

                                <template v-for="header in headers.filter((item) => item.type === 'asset')">
                                    <v-list-item :key="header.key">
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ header.title }}
                                        </v-list-item-content>

                                        {{ displayToHumanAssetMetricFromCompleteDTd(network_data, header.key) }}
                                    </v-list-item>
                                </template>
                            </v-list-group>

                            <v-list class="pa-0" rounded>
                                <v-list-group :value="true" prepend-icon="mdi-access-point-network">
                                    <template v-slot:activator>
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ $t('list_check_network.sensor_data') }}
                                        </v-list-item-content>
                                    </template>

                                    <!-- DEVICE METRICS -->
                                    <template v-if="headers.filter((item) => item.type === 'asset').length === 0">
                                        <template
                                            v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'all')"
                                        >
                                            <v-list-item :key="header.key">
                                                <v-list-item-content class="font-weight-medium pa-0">
                                                    {{ header.title }}
                                                </v-list-item-content>

                                                <template v-if="header.key === 'erreur'">
                                                    {{ displayToHumanError(network_data.erreur, network_data.hauteur_liquide) }}
                                                </template>

                                                <template v-else-if="header.key === 'hauteur_liquide'">
                                                    {{
                                                        !(network_data.hauteur_liquide < 0) && network_data.hauteur_liquide !== undefined
                                                            ? calculatedMeasure(network_data.hauteur_liquide)
                                                            : '/'
                                                    }}
                                                </template>

                                                <template v-else>
                                                    {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </template>

                                    <!-- REQUIRED METRICS -->
                                    <template
                                        v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'required')"
                                    >
                                        <v-list-item :key="header.key">
                                            <v-list-item-content class="font-weight-medium pa-0">
                                                {{ header.title }}
                                            </v-list-item-content>

                                            <template v-if="header.key === 'declencheur'">
                                                {{ displayToHumanTrigger(network_data.declencheur) }}
                                            </template>

                                            <template v-else-if="header.key === 'quality_network'">
                                                <div v-if="network_data.quality_network === null">
                                                    <v-icon class="network-icon" color="disabled"> mdi-network-strength-4-cog </v-icon>
                                                    {{ $t('list_check_network.no_data') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 0">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-off-outline </v-icon>
                                                    {{ $t('list_check_network.bad') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 1">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-1-alert </v-icon>
                                                    {{ $t('list_check_network.bad') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 2">
                                                    <v-icon class="network-icon" color="error"> mdi-network-strength-2-alert </v-icon>
                                                    {{ $t('list_check_network.medium') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 3">
                                                    <v-icon class="network-icon" color="warning"> mdi-network-strength-2 </v-icon>
                                                    {{ $t('list_check_network.medium') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 4">
                                                    <v-icon class="network-icon" color="green">mdi-network-strength-3 </v-icon>
                                                    {{ $t('list_check_network.good') }}
                                                </div>
                                                <div v-else-if="network_data.quality_network === 5">
                                                    <v-icon class="network-icon" color="green">mdi-network-strength-4 </v-icon>
                                                    {{ $t('list_check_network.very_good') }}
                                                </div>
                                            </template>

                                            <template v-else>
                                                {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-list-group>
                            </v-list>

                            <!-- ADDITIONAL DATA -->
                            <v-list class="pa-0" rounded>
                                <v-list-group prepend-icon="mdi-magnify-plus-outline">
                                    <template v-slot:activator>
                                        <v-list-item-content class="font-weight-medium pa-0">
                                            {{ $t('list_check_network.additional_data') }}
                                        </v-list-item-content>
                                    </template>

                                    <template v-if="headers.filter((item) => item.type === 'asset').length > 0">
                                        <!-- DEVICE METRICS -->
                                        <template
                                            v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'all')"
                                        >
                                            <v-list-item :key="header.key" class="p-4">
                                                <v-list-item-content class="font-weight-medium pa-0">
                                                    {{ header.title }}
                                                </v-list-item-content>

                                                <template v-if="header.key === 'erreur'">
                                                    {{ displayToHumanError(network_data.erreur, network_data.hauteur_liquide) }}
                                                </template>

                                                <template v-else-if="header.key === 'hauteur_liquide'">
                                                    {{
                                                        !(network_data.hauteur_liquide < 0) && network_data.hauteur_liquide !== undefined
                                                            ? calculatedMeasure(network_data.hauteur_liquide)
                                                            : '/'
                                                    }}
                                                </template>

                                                <template v-else>
                                                    {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </template>

                                    <!-- ADMIN METRICS -->
                                    <template
                                        v-for="header in headers.filter((item) => item.type === 'device' && item.display === 'admin')"
                                    >
                                        <v-list-item :key="header.key">
                                            <v-list-item-content class="font-weight-medium pa-0">
                                                {{ header.title }}
                                            </v-list-item-content>

                                            {{ network_data[header.key] ? network_data[header.key] : '-' }}
                                        </v-list-item>
                                    </template>
                                </v-list-group>
                            </v-list>

                            <v-divider v-if="index !== historical_traited_data.length - 1" class="mt-3" />
                        </div>
                    </v-list>
                </div>

                <div v-else class="font-14">
                    <v-list class="mt-2 text-center" dense>
                        {{ $t('list_check_network.no_data') }}
                    </v-list>
                </div>
            </div>
        </v-expand-transition>

        <revoke-device-traited-data-dialog ref="RevokeDeviceTraitedDataDialogComponent" />
    </div>
</template>

<script>
import ChipsLastStatement from './ChipsLastStatement'
import moment from 'moment'
import RevokeDeviceTraitedDataDialog from '@/components/RevokeDeviceTraitedDataDialogComponent.vue'

export default {
    name: 'ListCheckNetwork',
    components: {
        RevokeDeviceTraitedDataDialog,
        ChipsLastStatement,
    },
    props: {
        historical_traited_data: {
            type: Array,
            required: true,
        },
        is_checking_network: {
            type: Boolean,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
    },
    methods: {
        moment() {
            return moment
        },
        calculatedMeasure(measure) {
            return measure < 0 ? 0 : Math.round(measure)
        },
        openRevokeDeviceTraitedDataDialog(device_traited_data) {
            this.$refs.RevokeDeviceTraitedDataDialogComponent.openRevokeDeviceTraitedDataDialog(device_traited_data)
        },
        displayToHumanAssetMetricFromCompleteDTd(data, parameter) {
            const output = JSON.parse(parameter)['output']

            if (data[output[0]][output[1]] && data[output[0]][output[1]][output[2]] !== null) {
                return data[output[0]][output[1]][output[2]]
            }
            return '-'
        },
    },
}
</script>

<style>
hr {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.network-icon {
    margin-right: 5px;
}
</style>
