<template>
    <div id="global-loader" class="fullscreen center-loader">
        <div class="spinner"/>
        <span class="spinner-text fullscreen center-loader">{{ $store.state.loader.text_loader }}</span>
    </div>
</template>

<script>
export default {
    name: 'Loader',
}
</script>

<style>
.spinner {
    z-index: 99;
    width: 100px;
    height: 100px;
    background: transparent;
    border-top: 4px solid #03a9f4;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#global-loader {
    z-index: 99;
}

.center-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-text {
    top: 80px;
    z-index: 98;
}
</style>
