import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/user'

export default {
    getProfileAdminInformation(): Promise<AxiosResponse<IServerResponse<IProfileInfo>>> {
        return axios.get(`${resource}/admin/device_stats?limit=100`)
    },

    getDevicesInventoryOfAdminUser(): Promise<AxiosResponse<IServerResponse<any[]>>> {
        return axios.get(`${resource}/devices/inventory`)
    },
}
