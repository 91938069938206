<template>
    <div id="add-edit-client-dialog-component">
        <v-dialog v-model="is_add_edit_client_dialog_open" width="600">
            <v-card>
                <v-card-title>
                    {{ $t(is_new_client ? 'add_edit_client_dialog_component.add_client' : 'add_edit_client_dialog_component.edit_client') }}
                </v-card-title>

                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            v-model.trim="client.company_name"
                            :label="$t('associate_client_widget_component.company_name')"
                            :rules="[(v) => !!v || this.$t('generic_form_component.required_fields')]"
                            autofocus
                            filled
                        />

                        <v-text-field
                            v-model.trim="client.client_space_name"
                            :label="$t('associate_client_widget_component.client_space_name')"
                            filled
                        />

                        <v-text-field v-model.trim="client.siret" :label="$t('associate_client_widget_component.siren')" filled />

                        <v-text-field
                            v-model.trim="client.code_client"
                            :label="$t('associate_client_widget_component.client_reference')"
                            filled
                        />

                        <v-btn :loading="editing_client" block class="validation-button" large @click.prevent="prepareUpdateClient()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="is_add_edit_client_dialog_open = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ClientRepository from '@/repositories/ClientRepository'

export default {
    name: 'AddEditClientDialogComponent',
    data() {
        return {
            is_add_edit_client_dialog_open: false,
            is_new_client: false,
            client: {},
            editing_client: false,
            id_professional: 0,
        }
    },
    methods: {
        openEditClientDialog(client) {
            this.is_new_client = false
            this.client = { ...client }
            this.is_add_edit_client_dialog_open = true
        },

        openAddClientDialog(id_professional) {
            this.is_new_client = true
            this.client = {
                company_name: '',
                client_space_name: '',
                siret: '',
                code_client: '',
            }
            this.id_professional = id_professional
            this.is_add_edit_client_dialog_open = true
        },

        async prepareUpdateClient() {
            const valid = await this.$refs.form.validate()
            if (valid) {
                this.is_new_client ? this.addClient() : this.editClient()
            }
        },

        addClient() {
            this.editing_client = true
            ClientRepository.addClient({
                siret: this.client.siret,
                id_professionnel: this.id_professional,
                numero_professionnel: this.client.code_client,
                nom_entreprise: this.client.company_name,
                client_space_name: this.client.client_space_name,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('add_edit_client_dialog_component.added_client'),
                    })
                    this.$emit('added:client', this.client)
                    this.is_add_edit_client_dialog_open = false
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.editing_client = false
                })
        },

        editClient() {
            this.editing_client = true
            ClientRepository.editClient(this.client.id_client, {
                siret: this.client.siret,
                code_client: this.client.code_client,
                company_name: this.client.company_name,
                client_space_name: this.client.client_space_name,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('add_edit_client_dialog_component.edited_client'),
                    })
                    this.$emit('updated:client', this.client)
                    this.is_add_edit_client_dialog_open = false
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.editing_client = false
                })
        },
    },
}
</script>
