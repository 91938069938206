<template>
    <div id="widget-wrapper-component">
        <v-card elevation="0">
            <v-card-text>
                <div class="d-flex align-center justify-space-between pointer">
                    <div
                        class="font-weight-medium text-uppercase mb-0 font-18 d-flex align-center gap-2"
                        @click="is_widget_open = !is_widget_open"
                    >
                        <slot name="icon">
                            <v-icon>{{ icon }}</v-icon>
                        </slot>
                        <div class="pt-1">
                            <slot name="title">
                                {{ title }}
                            </slot>
                        </div>
                    </div>

                    <div class="grow" style="height: 24px" @click="is_widget_open = !is_widget_open" />

                    <div class="d-flex gap-2">
                        <v-icon @click="is_widget_open = !is_widget_open">
                            {{ is_widget_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>

                        <slot name="options" />
                    </div>
                </div>

                <v-expand-transition>
                    <template v-if="is_widget_open">
                        <div class="mt-3 text-skin-default">
                            <slot name="default" />
                        </div>
                    </template>
                </v-expand-transition>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'WidgetWrapperComponent',
    props: {
        icon: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        isAlreadyOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            is_widget_open: true,
        }
    },
    created() {
        this.is_widget_open = this.isAlreadyOpen
    },
}
</script>