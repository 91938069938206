import axios from 'axios'

const $axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
})

$axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if(error.response && ![401, 403, 409].includes(error.response.status)) {
            const token = window.localStorage.getItem('auth_token_default')

            let ip_client = 'unknown'
            axios.get('https://api.ipify.org?format=json').then((success) => {
                ip_client = success.data.ip
            })

            axios.post(
                `${process.env.VUE_APP_API_URL}api/v1/elastic_search/log`,
                {
                    error: error ?? 'empty',
                    message: error.response ?? 'empty',
                    ip_client: ip_client,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
        }

        //Catch on error on /auth/me to avoid infinite loading
        if (error.config.url === `${process.env.VUE_APP_API_URL}auth/me`) {
            document.dispatchEvent(new Event('logout'))
        }

        //Throw error for other request errors
        throw error
    },
)

export default $axios
