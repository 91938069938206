<template>
    <div id="asset-model-form-wrapper-component">
        <v-switch v-show="authorize_config_selection" v-model="model_configuration.is_required" :label="$t('global.activate')" inset />

        <v-expand-transition>
            <div v-if="model_configuration.is_required">
                <span class="font-14">{{ $t('asset_model_form_wrapper_component.select_container_type') }}</span>

                <v-row class="mb-4" dense>
                    <v-col v-for="asset_model in asset_module.asset_models" :key="asset_model.id_asset_model" cols="6">
                        <v-radio-group
                            v-model="model_configuration.id_asset_model"
                            class="mt-2"
                            hide-details
                            inline
                            @change="handleModelChange"
                        >
                            <div class="d-flex flex-column align-center">
                                <v-radio :value="asset_model.id_asset_model" class="ma-0 mb-1" />
                                <img :src="asset_model.path_model" alt="image asset" height="70" @error="setFallbackImage" />
                                <span class="font-12">{{ asset_model.name }}</span>
                            </div>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <div v-if="model_configuration.id_asset_model">
                        <generic-form-component
                            ref="genericForm"
                            :configuration="configuration"
                            :form_inputs="getModelById(model_configuration.id_asset_model).form_inputs"
                            v-on:update:configuration="handleConfigurationUpdate"
                        />

                        <v-select
                            v-model="model_configuration.id_asset_liquid"
                            :items="asset_liquids"
                            :label="$t('asset_model_form_wrapper_component.content')"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="[(v) => !!v || this.$t('generic_form_component.required_fields')]"
                            filled
                            item-text="name"
                            item-value="id_asset_liquid"
                        />
                    </div>
                </v-expand-transition>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import GenericFormComponent from '@/components/GenericFormComponent.vue'
import { nextTick } from 'vue'

export default {
    name: 'AssetModelFormWrapperComponent',
    components: { GenericFormComponent },
    data() {
        return {
            model_configuration: {
                is_required: this.configuration.is_required,
                id_asset_model: this.configuration.id_asset_model,
                id_asset_liquid: this.configuration.id_asset_liquid,
                configuration: {},
            },
            prefillFieldKey: [],
            prefillField: new Map(),
            lastInputChange: '',
        }
    },
    props: {
        asset_module: {
            type: Object,
            require: true,
        },
        //Configuration model brut from back
        configuration: {
            type: Object,
            require: true,
        },
        //Which column on the form
        column: {
            type: Number,
            require: true,
        },
        asset_liquids: {
            type: Array,
            required: true,
        },
        authorize_config_selection: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    methods: {
        getModelById(id_asset_model) {
            return this.asset_module.asset_models.find((assetModel) => assetModel.id_asset_model === id_asset_model, 1)
        },

        setFallbackImage(event) {
            event.target.src = '/img/fallback_asset.png'
        },

        emitUpdate() {
            this.$emit('update:model', { model_configuration: this.model_configuration, column: this.column })
        },

        handleModelChange() {
            this.prefillField.clear()
        },

        handleConfigurationUpdate(configuration) {
            this.model_configuration.configuration = configuration.form
            let tankTypes = this.asset_module.asset_models.find((model) => model.id_asset_model == this.model_configuration.id_asset_model)
                .config.tank_type

            if (tankTypes) {
                this.lastInputChange = configuration.lastInputChange

                switch (tankTypes) {
                    case 1:
                        this.managePrefillField(this.model_configuration.configuration, ['height', 'length', 'total_volume', 'width'])
                        this.calculateCuboid()
                        break
                    case 2:
                        this.managePrefillField(this.model_configuration.configuration, ['height', 'length', 'total_volume'])
                        this.calculateCylindrical()
                        break
                    case 3:
                        this.managePrefillField(this.model_configuration.configuration, ['height', 'total_volume', 'diameter'])
                        this.calculateCylindricalVertical()
                        break
                    case 4:
                        this.managePrefillField(this.model_configuration.configuration, ['height', 'total_volume'])
                        this.calculateSpherique()
                        break
                    case 5:
                        this.managePrefillField(this.model_configuration.configuration, ['height', 'total_volume'])
                        this.calculateIBC()
                        break
                }
            }

            nextTick(() => {
                this.$refs.genericForm.handleNewConfiguration(this.model_configuration.configuration)
                this.emitUpdate()
            })
        },

        managePrefillField(values, newPrefillFieldKey) {
            this.prefillFieldKey = newPrefillFieldKey

            this.prefillFieldKey.forEach((key) => {
                let value = values[key]
                if (!value || value === '0' || value < 0) {
                    this.prefillField.delete(key)
                } else {
                    this.prefillField.set(key, parseInt(value))
                }
            })
        },

        getKeyDoesNotExist() {
            this.prefillFieldKey.forEach((key) => {
                if (!this.prefillField.has(key)) {
                    return key
                }
            })
        },

        //region Cuboid
        calculateCuboid() {
            if (this.prefillField.size === 3) {
                if (this.prefillField.has('total_volume')) {
                    let key = this.getKeyDoesNotExist()
                    let value = (this.prefillField.get('total_volume') * 1000000) / this.getMultiplication(key)
                    this.highlightAndPutInput(key, value)
                } else {
                    this.calculateVolume()
                }
            }

            if (this.prefillField.size === 4) {
                switch (this.lastInputChange) {
                    case 'width':
                        this.calculateVolume()
                        break
                    default:
                        this.highlightAndPutInput(
                            'width',
                            (this.prefillField.get('total_volume') * 1000000) /
                                (this.prefillField.get('length') * this.prefillField.get('height')),
                        )
                        break
                }
            }
        },

        getMultiplication(key) {
            let multi = 1
            this.prefillFieldKey.forEach((local_key) => {
                if (local_key !== key) {
                    multi = multi * this.prefillField.get(local_key)
                }
            })

            return multi
        },

        calculateVolume() {
            this.highlightAndPutInput(
                'total_volume',
                (this.prefillField.get('width') * this.prefillField.get('height') * this.prefillField.get('length')) / 1000000,
            )
        },
        //endregion

        //region Cylindrical
        calculateCylindrical() {
            if (this.prefillField.has('total_volume') && this.prefillField.has('height')) {
                switch (this.lastInputChange) {
                    case 'length':
                        this.highlightAndPutInput(
                            'height',
                            this.calculateByVolumeAndX(this.prefillField.get('total_volume'), this.prefillField.get('length')),
                        )
                        break
                    case 'total_volume':
                        this.setValueLengthByCylindrical()
                        break
                    case 'height':
                        this.setValueLengthByCylindrical()
                        break
                }
                return
            }

            if (this.prefillField.has('total_volume') && this.prefillField.has('height') && !this.prefillField.has('length')) {
                this.setValueLengthByCylindrical()
            } else if (!this.prefillField.has('total_volume') && this.prefillField.has('height') && this.prefillField.has('length')) {
                this.highlightAndPutInput(
                    'total_volume',
                    this.calculateByWidthAndHeight(this.prefillField.get('length'), this.prefillField.get('height')),
                )
            } else if (this.prefillField.has('total_volume') && this.prefillField.has('length') && !this.prefillField.has('height')) {
                this.highlightAndPutInput(
                    'height',
                    this.calculateByVolumeAndX(this.prefillField.get('total_volume'), this.prefillField.get('length')),
                )
            }
        },

        setValueLengthByCylindrical() {
            this.highlightAndPutInput(
                'length',
                this.calculateByVolumeAndY(this.prefillField.get('total_volume'), this.prefillField.get('height')),
            )
        },
        //endregion

        //region CylindricalVertical
        calculateCylindricalVertical() {
            if (this.prefillField.has('total_volume') && this.prefillField.has('height') && this.prefillField.has('diameter')) {
                switch (this.lastInputChange) {
                    case 'diameter':
                        this.highlightAndPutInput(
                            'height',
                            this.calculateByVolumeAndY(this.prefillField.get('total_volume'), this.prefillField.get('diameter')),
                        )
                        break
                    case 'total_volume':
                        this.setValueLargeurLongeur()
                        break
                    case 'height':
                        this.setValueLargeurLongeur()
                        break
                    case 'type':
                        this.setValueLargeurLongeur()
                        break
                }
                return
            }
            if (this.prefillField.has('total_volume') && this.prefillField.has('height') && !this.prefillField.has('diameter')) {
                this.setValueLargeurLongeur()
            } else if (!this.prefillField.has('total_volume') && this.prefillField.has('height') && this.prefillField.has('diameter')) {
                this.highlightAndPutInput(
                    'total_volume',
                    this.calculateByWidthAndHeight(this.prefillField.get('diameter'), this.prefillField.get('height')),
                )
            } else if (this.prefillField.has('total_volume') && this.prefillField.has('diameter') && !this.prefillField.has('height')) {
                this.highlightAndPutInput(
                    'height',
                    this.calculateByVolumeAndY(this.prefillField.get('total_volume'), this.prefillField.get('diameter')),
                )
            }
        },

        setValueLargeurLongeur() {
            this.highlightAndPutInput(
                'diameter',
                this.calculateByVolumeAndX(this.prefillField.get('total_volume'), this.prefillField.get('height')),
            )
        },
        //endregion

        //region Spherique
        calculateSpherique() {
            if (this.prefillField.size > 1) {
                if (this.lastInputChange === 'total_volume') {
                    this.calculateRayon()
                } else {
                    this.highlightAndPutInput(
                        'total_volume',
                        Math.round(((4 / 3) * Math.PI * Math.pow(this.prefillField.get('height') / 2, 3)) / 1000000),
                    )
                }
            }
        },

        calculateRayon() {
            this.highlightAndPutInput(
                'height',
                Math.round(Math.pow((this.prefillField.get('total_volume') * 3) / (4 * Math.PI), 1 / 3) * 200),
            )
        },
        //endregion

        //region IBC
        calculateIBC() {
            if (this.prefillField.size > 0) {
                if (this.lastInputChange === 'total_volume') {
                    if (this.prefillField.has('total_volume')) {
                        this.highlightAndPutInput(
                            'height',
                            Math.round(Math.pow(this.prefillField.get('total_volume'), 1 / 3) * 100)
                        )
                    }
                } else {
                    this.highlightAndPutInput('total_volume', Math.round(Math.pow(this.prefillField.get('height'), 3) / 1000000))
                }
            }
        },
        //endregion

        calculateByVolumeAndX(x, y) {
            return Math.floor(2 * Math.sqrt((x * 1000000) / (Math.PI * y)))
        },

        calculateByVolumeAndY(x, y) {
            return Math.floor((x * 1000000) / (Math.PI * ((y / 2) * (y / 2))))
        },

        calculateByWidthAndHeight(x, y) {
            return Math.floor((Math.PI * (x / 2) * (x / 2) * y) / 1000000)
        },

        highlightAndPutInput(key, value) {
            this.prefillField.set(key, Math.floor(value))
            this.model_configuration.configuration[key] = Math.floor(value)
            this.inputEffectWhenChanged(key)
        },

        inputEffectWhenChanged(key) {
            let el = document.getElementById(key)
            if (el) {
                el.classList.add('animate-input-prefill-change')

                setTimeout(() => {
                    el.classList.remove('animate-input-prefill-change')
                }, 1000)
            }
        },
    },
}
</script>
