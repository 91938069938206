import Vue from 'vue'
import '@/plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Global from './plugins/global'
import './plugins/auth'
import VueMoment from 'vue-moment/dist/vue-moment.min'
import i18n from './plugins/i18n'
import moment from '@/plugins/moment'
import DatetimePicker from 'vuetify-datetime-picker/dist/index'
import 'vuetify/dist/vuetify.min.css';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueMoment, {
    moment
})
Vue.use(DatetimePicker)

//TODO: remove this package
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAqWP1SBO0dzsBugr2s125UjYKNm2sYbDU',
        libraries: 'places'
    },
    installComponents: true
})

Vue.config.productionTip = false
Vue.mixin(Global)

new Vue({
    router: router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
}).$mount('#app')
