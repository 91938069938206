import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/asset_liquid'

export default {
    postAddAssetLiquid(assetLiquid): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(resource, assetLiquid)
    },

    getAssetLiquidByIdModule(
        id_professional: number,
        id_asset_module: number,
    ): Promise<AxiosResponse<IServerResponse<Array<IAssetLiquid>>>> {
        return axios.get(`${resource}/professional/${id_professional}/module/${id_asset_module}`)
    },
}
