<template>
    <div id="profil">
        <v-container>
            <Header
                :text="$t('profile.find_profile_information_and_activity')"
                :title="$t('profile.my_profile')"
            />
            <div>
                <span class="font-weight-medium font-14">
                    <v-icon class="pr-2">mdi-account</v-icon>
                    {{ $t('profile.my_contact_details') }}
                </span>

                <div class="mt-2">
                    <p class="mb-0">
                        <span class="text">{{ $t('profile.first_name') }}: </span>
                        <span class="details">{{ $auth.user().first_name }}</span>
                    </p>

                    <p class="mb-0">
                        <span class="text">{{ $t('profile.last_name') }}: </span>
                        <span class="details">{{ $auth.user().last_name }}</span>
                    </p>

                    <p>
                        <span class="text">{{ $t('profile.email') }}: </span>
                        <span class="details">{{ $auth.user().email }}</span>
                    </p>
                </div>

                <v-divider/>

                <div class="mt-4">
                    <span class="font-weight-medium font-14">
                        <v-icon class="pr-2">mdi-clipboard-text-clock</v-icon>
                        {{ $t('profile.space_activity') }}
                    </span>

                    <v-data-table
                        :headers="headers"
                        :items="profileInformation.device_traceability"
                        :loading="loading"
                        :search="search"
                        class="mt-2"
                        item-key="actions"
                    >
                        <template v-slot:top>
                            <v-text-field v-model="search" :label="$t('profile.search')" class="mx-4 pt-6"/>
                        </template>

                        <template v-slot:item.date="{ item }">
                            <chips-last-statement :danger="345600" :date="item.date" :warning="172800"/>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <span v-html="item.action + ' - ' + item.value"/>
                        </template>
                    </v-data-table>
                </div>
                <v-btn block class="logout-btn red--text mt-2" large text @click="$auth.logout()">
                    {{ $t('profile.disconnect_me') }}
                </v-btn>
            </div>
        </v-container>
    </div>
</template>
<script>
import Header from '../../components/Header'
import ChipsLastStatement from '../../components/ChipsLastStatement'
import UserRepository from '../../repositories/UserRepository'

export default {
    name: 'Profil',
    components: {
        Header,
        ChipsLastStatement,
    },
    created() {
        this.getProfileInformation()
    },
    data() {
        return {
            profileInformation: [],
            search: '',

            loading: false,

            headers: [
                {
                    text: this.$t('profile.date'),
                    value: 'date',
                },
                {
                    text: this.$t('profile.action'),
                    value: 'action',
                },
            ],
        }
    },
    methods: {
        getProfileInformation() {
            this.loading = true
            UserRepository.getProfileAdminInformation()
                .then((success) => {
                    this.profileInformation = success.data.data
                })
                .catch((error) => {
                    this.loading = false
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>

<style>
.details {
    color: #788a9e;
    font-size: 14px;
    font-weight: bold;
}

.nb-devices {
    font-weight: bold;
    color: #4c545f;
    font-size: 20px;
}

.v-data-footer > .v-data-footer__pagination {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.v-data-footer .v-input--hide-details {
    margin-left: 5px !important;
    margin-right: 2px !important;
}

.v-data-footer .v-data-footer__select {
    margin-right: 2px !important;
}

.v-label {
    font-weight: normal !important;
}
</style>
