<template>
    <div id="edit-asset-localisation-dialog-component">
        <v-dialog v-model="is_edit_asset_localisation_dialog_open" width="800">
            <v-card>
                <v-card-title class="mb-4">
                    {{ $t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation') }}
                </v-card-title>
                <v-card-text>
                    <map-coordinate-component
                        :coordinate="{ latitude: asset.latitude, longitude: asset.longitude }"
                        v-on:change:coordinate="handleCoordinateChange"
                    />

                    <div class="mb-4 text-center">
                        <div v-if="!geolocation_activate" class="mt-2">
                            <span class="error--text font-weight-bold">
                                {{ $t('edit_equipment.check_geolocation_parameter') }}
                            </span>

                            <v-icon color="error" dense @click="openGeolocationProcedureDialog"> mdi-help-circle </v-icon>
                        </div>
                    </div>

                    <v-btn
                        :loading="editing_asset_localisation"
                        block
                        class="validation-button"
                        large
                        @click.prevent="putAssetLocalization()"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="is_edit_asset_localisation_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <geolocation-activation-procedure-dialog-component ref="GeolocationActivationProcedureDialog" />
    </div>
</template>

<script>
import GeolocationActivationProcedureDialogComponent from '@/components/GeolocationActivationProcedureDialogComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import MapCoordinateComponent from '@/components/site/MapCoordinateComponent.vue'
import AutocompleteComponent from '@/components/site/AutocompleteComponent.vue'

export default {
    name: 'EditAssetLocalisationDialogComponent',
    components: { AutocompleteComponent, MapCoordinateComponent, GeolocationActivationProcedureDialogComponent },
    data() {
        return {
            asset: {},

            is_edit_asset_localisation_dialog_open: false,
            fetching_domiciles: true,
            geolocation_activate: true,
            editing_asset_localisation: false,
        }
    },
    methods: {
        openEditAssetLocalisationDialog(asset) {
            this.asset = { ...asset }
            this.is_edit_asset_localisation_dialog_open = true
        },

        openGeolocationProcedureDialog() {
            this.$refs.GeolocationActivationProcedureDialog.openGeolocationActivationProcedureDialog()
        },

        handleCoordinateChange(position) {
            this.asset.latitude = position.lat()
            this.asset.longitude = position.lng()
        },

        async putAssetLocalization() {
            if (this.asset.latitude && this.asset.longitude) {
                this.editing_asset_localisation = true
                AssetRepository.putAssetLocalization(this.asset.id_asset, {
                    latitude: this.asset.latitude,
                    longitude: this.asset.longitude,
                })
                    .then((success) => {
                        this.$emit('refresh:form')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.editing_asset_localisation = false
                    })
            }
        },
    },
}
</script>
