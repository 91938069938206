import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {
            name: 'dashboard',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: require('../views/pages/Login.vue').default,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('../views/pages/Dashboard.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/check_network',
        name: 'check_network',
        component: require('../views/pages/CheckNetwork').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/order_devices',
        name: 'order_devices',
        component: require('../views/pages/OrderDevices').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/return_devices',
        name: 'return_devices',
        component: require('../views/pages/ReturnDevices').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/edit_equipment',
        name: 'edit_equipment',
        component: require('../views/pages/EditEquipment').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/listen_mode',
        name: 'listen_mode',
        component: require('../views/pages/ListenMode.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/manage_stock',
        name: 'manage_stock',
        component: require('../views/pages/ManageStock.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: require('../views/pages/Profile.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/qr_code',
        name: 'qr_code',
        component: require('../views/pages/QrCode.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/send_downlink',
        name: 'send_downlink',
        component: require('../views/pages/SendDownlink.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/send_sms',
        name: 'send_sms',
        component: require('../views/pages/SendSmsPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/link_network_device',
        name: 'link-network-device',
        component: require('../views/pages/LinkNeworkDevice.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/sensor_in_stock',
        name: 'sensor-in-stock',
        component: require('../views/pages/SensorInStock.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/*',
        component: require('../views/pages/NotFound').default,
    },
]

const router = new VueRouter({
    routes: routes,
    mode: 'history',
})

export default router
