import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/domicile'

export default {
    addDomicile(domicile: any): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}`, domicile)
    },

    patchUpdateDomicileAndContactSite(domicile: any): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.patch(`${resource}/${domicile.id_domicile}`, domicile)
    },

    putUpdateDomicile(domicile: any): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${domicile.id_domicile}`, domicile)
    },
}
