<template>
    <div id="card-value-subtitle-component">
        <v-card class="rounded-xl shadow-md pa-4">
            <p class="mb-0 font-weight-bold font-16 text-skin-default">{{ value }}</p>
            <p class="mb-0 font-14 text-skin-medium">{{ subtitle }}</p>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'CardValueSubtitleComponent',
    props: {
        value: {
            type: Number | String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
    },
}
</script>