import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { IAssetModule } from '@/models/IAssetModule'

const resource = '/api/v1/module'

export default {
    getModuleById(id_module): Promise<AxiosResponse<IServerResponse<IAssetModule>>> {
        return axios.get(`${resource}/${id_module}`)
    },
}
