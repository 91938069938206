import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

export default {
    getContracts(id_professional: number, devices_number: string): Promise<AxiosResponse<IServerResponse<Array<IContract>>>> {
        return axios.get('/api/v1/device/contracts/' + id_professional, {
            params: {
                device_number: devices_number,
            },
        })
    },
}
