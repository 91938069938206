<template>
    <div id="dissociate-client-to-asset-dialog-component">
        <v-dialog v-model="is_dissociate_client_to_asset_dialog_open" width="600">
            <v-card>
                <v-card-title> {{ $t('dissociate_client_to_asset_dialog_component.dissociate_client') }}</v-card-title>
                <v-card-text>
                    <p
                        v-if="asset.client"
                        v-html="
                            $t('dissociate_client_to_asset_dialog_component.will_dissociate_client_to_equipment', [
                                asset.client.client_space_name,
                                asset.name,
                            ])
                        "
                    />

                    <v-btn :loading="dissociating_asset_to_client" block color="error" large @click.prevent="putDissociateAssetToClient()">
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="is_dissociate_client_to_asset_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AssetRepository from '@/repositories/AssetRepository'

export default {
    name: 'DissociateClientToAssetDialogComponent',
    data() {
        return {
            asset: {},
            is_dissociate_client_to_asset_dialog_open: false,
            dissociating_asset_to_client: false,
        }
    },
    methods: {
        openDissociateClientToAssetDialog(newAsset) {
            this.asset = newAsset
            this.is_dissociate_client_to_asset_dialog_open = true
        },

        putDissociateAssetToClient() {
            this.dissociating_asset_to_client = true
            AssetRepository.putDissociateAssetToClient(this.asset.id_asset)
                .then(() => {
                    this.is_dissociate_client_to_asset_dialog_open = false
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('dissociate_client_to_asset_dialog_component.dissociated_client'),
                    })
                    this.$emit('dissociated:client')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dissociating_asset_to_client = false
                })
        },
    },
}
</script>
