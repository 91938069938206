<template>
    <div id="menu-actions-component">
        <v-menu v-if="actions.filter((action) => action.hasAccess).length > 0" v-model="isMenuActionsOpen" bottom left>
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </div>
            </template>

            <v-list class="p-0 !shadow-sm !shadow-gray-400" elevation="0">
                <template v-for="action in actions">
                    <v-list-item v-if="action.hasAccess" :key="action.emit" :data-cy="action.emit" @click="$emit(action.emit)">
                        <v-icon :color="action.color" class="mr-2">{{ action.icon }}</v-icon>

                        <span :class="`${action.color}--text`">{{ action.title }}</span>
                    </v-list-item>
                </template>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'MenuActionsComponent',
    data() {
        return {
            isMenuActionsOpen: false,
        }
    },
    props: {
        actions: {
            type: Array,
            required: true,
        },
    },
}
</script>
