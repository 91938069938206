import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/model_reference'

export default {
    getModelReference(id_brand_reference): Promise<AxiosResponse<IServerResponse<Array<IModelReference>>>> {
        return axios.get(`${resource}/${id_brand_reference}`)
    },
}
