import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"localisation-asset-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('localisation_asset_widget_component.asset_localisation'),"icon":"mdi-home","is-already-open":""},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('menu-actions-component',{attrs:{"actions":[
                    {
                        title: _vm.$t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation'),
                        icon: 'mdi-swap-horizontal',
                        emit: 'openEditAssetLocalisationDialog',
                        hasAccess: true,
                    } ]},on:{"openEditAssetLocalisationDialog":function($event){return _vm.openEditAssetLocalisationDialog()}}})]},proxy:true},{key:"default",fn:function(){return [(_vm.asset.latitude && _vm.asset.longitude)?_c('div',[_c('map-coordinate-component',{attrs:{"coordinate":{ latitude: _vm.asset.latitude, longitude: _vm.asset.longitude },"readonly":""}})],1):_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('localisation_asset_widget_component.no_location_for_this_equipment'))+" ")]),_c(VBtn,{staticClass:"validation-button mb-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditAssetLocalisationDialog()}}},[_c(VIcon,{staticClass:"mr-1",attrs:{"dense":""}},[_vm._v("mdi-crosshairs-gps")]),_vm._v(" "+_vm._s(_vm.$t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation'))+" ")],1)],1)]},proxy:true}])}),_c('edit-asset-localisation-dialog-component',{ref:"editAssetLocalisationDialog",on:{"refresh:form":function($event){return _vm.$emit('refresh:form')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }