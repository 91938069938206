import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"menu-actions-component"}},[(_vm.actions.filter(function (action) { return action.hasAccess; }).length > 0)?_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,684186491),model:{value:(_vm.isMenuActionsOpen),callback:function ($$v) {_vm.isMenuActionsOpen=$$v},expression:"isMenuActionsOpen"}},[_c(VList,{staticClass:"p-0 !shadow-sm !shadow-gray-400",attrs:{"elevation":"0"}},[_vm._l((_vm.actions),function(action){return [(action.hasAccess)?_c(VListItem,{key:action.emit,attrs:{"data-cy":action.emit},on:{"click":function($event){return _vm.$emit(action.emit)}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":action.color}},[_vm._v(_vm._s(action.icon))]),_c('span',{class:((action.color) + "--text")},[_vm._v(_vm._s(action.title))])],1):_vm._e()]})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }