import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profil"}},[_c(VContainer,[_c('Header',{attrs:{"text":_vm.$t('profile.find_profile_information_and_activity'),"title":_vm.$t('profile.my_profile')}}),_c('div',[_c('span',{staticClass:"font-weight-medium font-14"},[_c(VIcon,{staticClass:"pr-2"},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.$t('profile.my_contact_details'))+" ")],1),_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('profile.first_name'))+": ")]),_c('span',{staticClass:"details"},[_vm._v(_vm._s(_vm.$auth.user().first_name))])]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('profile.last_name'))+": ")]),_c('span',{staticClass:"details"},[_vm._v(_vm._s(_vm.$auth.user().last_name))])]),_c('p',[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('profile.email'))+": ")]),_c('span',{staticClass:"details"},[_vm._v(_vm._s(_vm.$auth.user().email))])])]),_c(VDivider),_c('div',{staticClass:"mt-4"},[_c('span',{staticClass:"font-weight-medium font-14"},[_c(VIcon,{staticClass:"pr-2"},[_vm._v("mdi-clipboard-text-clock")]),_vm._v(" "+_vm._s(_vm.$t('profile.space_activity'))+" ")],1),_c(VDataTable,{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.profileInformation.device_traceability,"loading":_vm.loading,"search":_vm.search,"item-key":"actions"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mx-4 pt-6",attrs:{"label":_vm.$t('profile.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('chips-last-statement',{attrs:{"danger":345600,"date":item.date,"warning":172800}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.action + ' - ' + item.value)}})]}}])})],1),_c(VBtn,{staticClass:"logout-btn red--text mt-2",attrs:{"block":"","large":"","text":""},on:{"click":function($event){return _vm.$auth.logout()}}},[_vm._v(" "+_vm._s(_vm.$t('profile.disconnect_me'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }