<template>
    <div id="associate-client-to-asset-dialog-component">
        <v-dialog v-model="is_associate_client_to_asset_dialog_open" width="600">
            <v-card>
                <v-card-title>{{ $t('associate_client_to_asset_dialog_component.associate_client') }}</v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-autocomplete
                            v-model="client"
                            :items="clients"
                            :label="$t('associate_client_to_asset_dialog_component.client')"
                            :loading="fetching_client"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :no-data-text="$t('associate_client_to_asset_dialog_component.no_client_available')"
                            :rules="[(v) => !!v.id_client || this.$t('generic_form_component.required_fields')]"
                            filled
                            item-text="client_space_name"
                            item-value="id_client"
                            return-object
                            @change="fetchClientDomiciles"
                        >
                            <template v-slot:prepend-item>
                                <v-btn block text @click="openAddClientDialog">
                                    <v-icon>mdi-plus</v-icon>
                                    {{ $t('global.add') }}
                                </v-btn>
                            </template>
                        </v-autocomplete>

                        <v-select
                            v-model="domicile"
                            :item-text="
                                (item) => {
                                    return displayHumanSiteAddress(item)
                                }
                            "
                            :items="domiciles"
                            :label="$t('exchange_asset_domicile_dialog_component.client_site')"
                            :loading="fetching_domiciles"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :no-data-text="$t('exchange_asset_domicile_dialog_component.no_client_site')"
                            :rules="[(v) => !!v.id_domicile || this.$t('generic_form_component.required_fields')]"
                            filled
                            item-value="id_domicile"
                            return-object
                        >
                            <template v-slot:prepend-item>
                                <v-btn block text @click="openAddClientDomicileDialog">
                                    <v-icon>mdi-plus</v-icon>
                                    {{ $t('global.add') }}
                                </v-btn>
                            </template>
                        </v-select>

                        <v-btn
                            :loading="associating_client_to_asset"
                            block
                            class="validation-button"
                            large
                            @click.prevent="associateAssetToClient()"
                        >
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="is_associate_client_to_asset_dialog_open = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <add-edit-client-dialog-component ref="addEditClientDialog" v-on:added:client="fetchMinimalProfessionalClients()" />
        <add-edit-client-domicile-dialog-component ref="addEditClientDomicileDialog" v-on:added:domicile="fetchClientDomiciles" />
    </div>
</template>

<script>
import ProfessionalRepository from '@/repositories/ProfessionalRepository'
import ClientRepository from '@/repositories/ClientRepository'
import AssetRepository from '@/repositories/AssetRepository'
import AddEditClientDialogComponent from '@/components/client/AddEditClientDialogComponent.vue'
import AddEditClientDomicileDialogComponent from '@/components/site/AddEditClientDomicileDialogComponent.vue'

export default {
    name: 'AssociateClientToAssetDialogComponent',
    components: { AddEditClientDomicileDialogComponent, AddEditClientDialogComponent },
    data() {
        return {
            is_associate_client_to_asset_dialog_open: false,
            asset: {},

            clients: [],
            client: {},
            fetching_client: true,

            domiciles: [],
            domicile: {},
            fetching_domiciles: false,

            associating_client_to_asset: false,
        }
    },
    methods: {
        openAssociateClientToAssetDialog(asset) {
            this.asset = asset
            this.domicile = {}
            this.client = {}

            this.is_associate_client_to_asset_dialog_open = true
            this.fetchMinimalProfessionalClients()
        },

        openAddClientDialog() {
            this.$refs.addEditClientDialog.openAddClientDialog(this.asset.id_professional)
        },

        openAddClientDomicileDialog() {
            this.$refs.addEditClientDomicileDialog.openAddClientDomicileDialog(this.client)
        },

        fetchMinimalProfessionalClients() {
            this.fetching_client = true
            ProfessionalRepository.getMinimalProfessionalClients(this.asset.id_professional)
                .then((success) => {
                    this.clients = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_client = false
                })
        },

        fetchClientDomiciles() {
            this.fetching_domiciles = true
            this.domicile = {}
            ClientRepository.getClientDomiciles(this.client.id_client)
                .then((success) => {
                    this.domiciles = success.data.data
                    if (this.domiciles.length === 1) {
                        this.domicile = this.domiciles[0]
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_domiciles = false
                })
        },

        async associateAssetToClient() {
            const valid = await this.$refs.form.validate()
            if (valid) {
                this.associating_client_to_asset = true
                AssetRepository.postAssociateAssetToClient(this.asset.id_asset, this.client.id_client, this.domicile.id_domicile)
                    .then(() => {
                        this.is_associate_client_to_asset_dialog_open = false
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'success',
                            text: this.$t('associate_client_to_asset_dialog_component.associated_client'),
                        })
                        this.$emit('associated:client')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.associating_client_to_asset = false
                    })
            }
        },
    },
}
</script>
