import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import general from '@/store/modules/general'
import loader from '@/store/modules/loader'
import successAlert from '@/store/modules/successAlert'
import snackbar from '@/store/modules/snackbar'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auto_refresh: 0,
        last_devices_entered: [],
    },
    mutations: {
        setAutoRefresh(state, value) {
            state.auto_refresh = value
        },

        clearIntervalAutoRefresh(state) {
            clearInterval(state.auto_refresh)
        },

        addLastDeviceEntered(state, new_last_devices_entered: string) {
            // @ts-ignore
            if (!state.last_devices_entered.includes(new_last_devices_entered)) {
                if (state.last_devices_entered.length >= 3) {
                    state.last_devices_entered.shift()
                }
                // @ts-ignore
                state.last_devices_entered.push(new_last_devices_entered)
                const parsed = JSON.stringify(state.last_devices_entered)
                localStorage.setItem('last_devices_entered', parsed)
            }
        },

        removeLastDeviceEntered(state, x) {
            state.last_devices_entered.splice(x, 1)

            const parsed = JSON.stringify(state.last_devices_entered)
            localStorage.setItem('last_devices_entered', parsed)
        },
    },
    actions: {},
    modules: {
        general,
        loader,
        successAlert,
        snackbar,
    },
    plugins: [createPersistedState()],
})
