<template>
    <div id="header">
        <div class="header">
            <v-row class="mt-5">
                <v-col class="test">
                    <v-icon @click="goToDashboard()" color="blue">mdi-arrow-left</v-icon>
                </v-col>
                <v-col>
                    <p class="little-title font-weight-bold">{{ title }}</p>
                </v-col>
            </v-row>
        </div>
        <p class="text text-header">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            require: true,
        },
        text: {
            type: String,
            require: true,
        },
    },
    methods: {
        goToDashboard() {
            this.$router.push('/dashboard')
        },
    },
}
</script>

<style>
.header {
    position: fixed;
    width: 100%;
    top: 0;
    height: 70px;
    z-index: 97;
    background-color: #f5f7fb;
    margin-bottom: 10px;
}

.text-header {
    margin-top: 70px !important;
}
.test {
    max-width: 40px !important;
}
</style>
