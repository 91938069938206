<template>
    <v-chip :color="getColor()" outlined small>
        <span v-if="date !== ''">{{ date | moment('from', 'now') }}</span>
        <span v-else>
            {{ $t('chips_last_statement.no_statement') }}
        </span>
    </v-chip>
</template>

<script>
export default {
    name: 'ChipsLastStatement',
    props: {
        date: {
            type: String,
            require: true,
        },
        danger: {
            type: Number,
            require: true,
        },
        warning: {
            type: Number,
            require: true,
        },
    },
    methods: {
        getColor() {
            let color = 'success'
            if (this.date === '') {
                color = 'disable'
            }
            let dif = Math.abs((new Date(this.date).getTime() - new Date().getTime()) / 1000)
            if (dif > this.danger) {
                color = 'error'
            } else if (dif > this.warning) {
                color = 'warning'
            }
            return color
        },
    },
}
</script>
