<template>
    <div id="associate-site-to-asset-widget-component">
        <widget-wrapper-component :title="$t('associate_site_to_asset_widget_component.site_location')" icon="mdi-home" is-already-open>
            <template v-slot:options>
                <menu-actions-component
                    v-if="asset.client"
                    :actions="[
                        {
                            title: $t('exchange_asset_domicile_dialog_component.associate_new_site'),
                            icon: 'mdi-swap-horizontal',
                            emit: 'openExchangeAssetDomicileDialog',
                            hasAccess: true,
                        },
                        {
                            title: $t('edit_contact_on_site_component.edit_contact_on_site'),
                            icon: 'mdi-account',
                            emit: 'openEditContactOnSiteDialog',
                            hasAccess: true,
                        },
                        {
                            title: $t('edit_contact_on_site_component.edit_site'),
                            icon: 'mdi-home',
                            emit: 'openAddEditDomicileClientDialog',
                            hasAccess: true,
                        },
                    ]"
                    v-on:openEditContactOnSiteDialog="openEditContactOnSiteDialog()"
                    v-on:openExchangeAssetDomicileDialog="openExchangeAssetDomicileDialog()"
                    v-on:openAddEditDomicileClientDialog="openAddEditClientDomicileDialog()"
                />
            </template>

            <template v-slot:default>
                <template v-if="asset.domicile">
                    <div class="mb-4">
                        <div class="d-flex align-center mb-1">
                            <v-icon>mdi-map-marker-outline</v-icon>
                            {{ displayHumanSiteAddress(asset.domicile) }}
                        </div>

                        <div class="d-flex align-center">
                            <v-icon>mdi-home</v-icon>
                            <p class="mb-0">
                                {{ asset.domicile.address_name }}
                            </p>
                        </div>
                    </div>

                    <!-- CONTACT ON SITE -->
                    <div>
                        <p class="mb-2 text-uppercase font-weight-bold text-skin-medium">
                            {{ $t('edit_contact_on_site_component.contact_on_site') }}
                        </p>

                        <template v-if="!isContactOnSiteFill(asset.domicile)">
                            {{ $t('associate_site_to_asset_widget_component.on_site_contact_not_specified') }}
                        </template>

                        <div class="text-sm d-flex flex-column gap-2 mb-2">
                            <div
                                v-if="asset.domicile.contact_first_name || asset.domicile.contact_last_name"
                                class="d-flex gap-1 align-center"
                            >
                                <v-icon class="text-skin-medium">mdi-account</v-icon>
                                <span class="text-skin-default">
                                    {{ asset.domicile.contact_first_name }} {{ asset.domicile.contact_last_name }}
                                </span>
                            </div>

                            <div v-if="asset.domicile.contact_mail" class="d-flex gap-1 align-center">
                                <v-icon class="text-skin-medium">mdi-at</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_mail }}</span>
                            </div>

                            <div v-if="asset.domicile.contact_phone" class="d-flex gap-1 align-center">
                                <v-icon class="text-skin-medium">mdi-phone</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_phone }}</span>
                            </div>

                            <div v-if="asset.domicile.contact_note" class="d-flex gap-1 align-center">
                                <v-icon class="text-skin-medium">mdi-pencil</v-icon>
                                <span class="text-skin-default">{{ asset.domicile.contact_note }}</span>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <p class="mb-0">{{ $t('associate_site_to_asset_widget_component.no_site_associate') }}</p>
                </template>
            </template>
        </widget-wrapper-component>

        <exchange-asset-domicile-dialog-component ref="exchangeAssetDomicileDialog" v-on:refresh:form="$emit('refresh:form')" />
        <edit-contact-on-site-dialog-component ref="editContactOnSiteDialog" v-on:refresh:form="$emit('refresh:form')" />
        <add-edit-client-domicile-dialog-component ref="addEditClientDomicileDialog"  v-on:updated:domicile="$emit('refresh:form')" v-on:refresh:form="$emit('refresh:form')" without-contact-on-site/>
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'
import ExchangeAssetDomicileDialogComponent from '@/components/site/ExchangeAssetDomicileDialogComponent.vue'
import MenuActionsComponent from '@/components/MenuActionsComponent.vue'
import AssociateClientWidgetComponent from '@/components/client/AssociateClientWidgetComponent.vue'
import EditContactOnSiteDialogComponent from '@/components/site/EditContactOnSiteDialogComponent.vue'
import AddEditClientDomicileDialogComponent from '@/components/site/AddEditClientDomicileDialogComponent.vue'

export default {
    name: 'associate-site-to-asset-widget-component',
    components: {
        AddEditClientDomicileDialogComponent,
        EditContactOnSiteDialogComponent,
        AssociateClientWidgetComponent,
        MenuActionsComponent,
        ExchangeAssetDomicileDialogComponent,
        WidgetWrapperComponent,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openExchangeAssetDomicileDialog() {
            this.$refs.exchangeAssetDomicileDialog.openExchangeAssetDomicileDialog(this.asset)
        },
        openEditContactOnSiteDialog() {
            this.$refs.editContactOnSiteDialog.openEditContactOnSiteDialog(this.asset.domicile)
        },
        isContactOnSiteFill(contact) {
            return (
                !!contact.contact_mail ||
                !!contact.contact_first_name ||
                !!contact.contact_last_name ||
                !!contact.contact_note ||
                !!contact.contact_phone
            )
        },
        openAddEditClientDomicileDialog() {
            this.$refs.addEditClientDomicileDialog.openEditClientDomicileDialog(this.asset.client, this.asset.domicile)
        }
    },
}
</script>
