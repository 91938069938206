<template>
    <div>
        <div class="mb-2">
            <autocomplete-component v-on:change:address="handlePlaceChange" />
            <p class="mb-0 font-12 text-skin-light">
                {{ $t('map_address_component.enter_location_or') }}
                <a @click="geolocateMe()">{{ $t('map_address_component.geolocate_you') }}</a>
            </p>
        </div>

        <div :id="map_uid" class="mb-4 rounded map-container" />

        <v-select
            v-model="address"
            :items="address_near_to_coordinate"
            :label="$t('map_address_component.nearby_addresses')"
            :loading="fetching_near_address"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => !!v.formatted_address || this.$t('generic_form_component.required_fields')]"
            class="mb-0"
            filled
            item-text="formatted_address"
            return-object
            v-on:change="handleAddressChange"
        >
        </v-select>
    </div>
</template>

<script>
import { mapStyle } from '@/assets/mapStyle.json'
import { uid } from 'uid'
import AutocompleteComponent from '@/components/site/AutocompleteComponent.vue'
import axios from 'axios'

export default {
    name: 'MapAddressComponent',
    components: { AutocompleteComponent },
    data() {
        return {
            map_uid: `map-address-${uid()}`,
            map: {},
            bounds_map: {},
            address: {},
            marker: {},
            address_near_to_coordinate: [],
            fetching_near_address: false,
        }
    },
    async mounted() {
        const { Map } = await google.maps.importLibrary('maps')
        this.bounds_map = new google.maps.LatLngBounds()
        this.map = new Map(document.getElementById(this.map_uid), {
            center: { lat: 47, lng: 4 },
            zoom: 5,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite'],
            },
            styles: mapStyle,
            maxZoom: 18,
        })

        this.map.addListener('click', (pos) => {
            this.marker.setPosition(pos.latLng)
            this.getValidAddressNearToCoordinate(pos.latLng.lat(), pos.latLng.lng())
        })

        this.marker = new google.maps.Marker({ map: this.map, position: null })
    },
    methods: {
        geolocateMe() {
            navigator.geolocation.getCurrentPosition((pos) => {
                const position = new google.maps.LatLng({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                })

                this.bounds_map.extend(position)
                this.marker.setPosition(position)

                this.map.fitBounds(this.bounds_map)
                this.getValidAddressNearToCoordinate(pos.coords.latitude, pos.coords.longitude)
            })
        },

        handlePlaceChange(place) {
            const location = place.geometry.location
            this.marker.setPosition(location)
            this.bounds_map.extend(location)
            this.map.fitBounds(this.bounds_map)
            this.getValidAddressNearToCoordinate(location.lat(), location.lng())
        },

        isAddressValid(address) {
            let count = 0
            address.address_components.forEach((component) => {
                if (
                    component.types.includes('locality') ||
                    component.types.includes('postal_town') ||
                    component.types.includes('country') ||
                    component.types.includes('postal_code')
                ) {
                    count++
                }
            })
            return count >= 3
        },

        getValidAddressNearToCoordinate(lat, lng) {
            this.address_near_to_coordinate = []
            this.fetching_near_address = true
            axios
                .get(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`,
                    {
                        transformRequest: (data, headers) => {
                            delete headers['Authorization']
                        },
                    },
                )
                .then((success) => {
                    success.data.results.forEach((address) => {
                        if (this.isAddressValid(address)) {
                            this.address_near_to_coordinate.push(address)
                        }
                    })

                    if (this.address_near_to_coordinate.length <= 0) {
                        // snackbar.showSnackbar(t('edit_address_domicile_component.no_nearby_address'), 'warning')
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_near_address = false
                })
        },

        handleAddressChange() {
            this.$emit('change:address', this.address)
        },
    },
}
</script>
