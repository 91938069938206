<template>
    <div id="qr-code-scanner-component">
        <v-icon class="return-button" color="blue" large @click="cancel()">mdi-arrow-left</v-icon>
        <v-icon v-if="is_torch_supported" class="torch-button" color="blue" large @click="updateTorchState(!is_torch_active)">
            {{ is_torch_active ? 'mdi-flashlight' : 'mdi-flashlight-off' }}
        </v-icon>

        <div id="qr-code-full-region" />
    </div>
</template>

<script>
export default {
    name: 'QrCodeScannerComponent',
    mounted() {
        this.html5QrCode = new Html5Qrcode('qr-code-full-region')
        const config = { fps: 10, qrbox: { width: 250, height: 250 } }
        // If you want to prefer front camera
        this.html5QrCode.start({ facingMode: 'environment' }, config, this.qrCodeSuccessCallback).then(() => {
            this.isTorchSupported()
        })
    },
    props: {
        qrbox: {
            type: Number,
            default: 400,
        },
        fps: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {
            html5QrCode: {},
            is_torch_supported: false,
            is_torch_active: false,
        }
    },
    methods: {
        isTorchSupported() {
            let settings = this.html5QrCode.getRunningTrackSettings()
            this.is_torch_supported = 'torch' in settings
        },

        async updateTorchState(newState) {
            this.is_torch_active = newState

            let constraints = {
                torch: this.is_torch_active,
                advanced: [{ torch: this.is_torch_active }],
            }

            await this.html5QrCode.applyVideoConstraints(constraints)
            this.html5QrCode.getRunningTrackSettings()
        },

        qrCodeSuccessCallback(decodedText, decodedResult) {
            this.$emit('scanned', decodedText)
            this.html5QrCode.stop()
        },

        cancel() {
            this.html5QrCode.stop()
            this.$emit('cancel')
        },
    },
}
</script>

<style>
#qr-code-scanner-component {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: black;
}

#qr-code-full-region {
    width: 100%;
    max-width: 1200px;
}

.return-button {
    position: fixed !important;
    left: 10px;
    z-index: 1000;
    top: 10px;
}

.torch-button {
    position: fixed !important;
    right: 10px;
    z-index: 1000;
    top: 10px;
}
</style>
