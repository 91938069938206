import Vue from 'vue'
import router from '../router/index'
import axios from '@/plugins/axios'

import auth from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

Vue.use(auth, {
    plugins: {
        http: axios,
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
    },
    options: {
        authRedirect: {
            url: process.env.VUE_APP_INSTALLATION_URL + 'login',
        },
        loginData: {
            url: process.env.VUE_APP_API_URL + 'auth/user_admin',
            method: 'POST',
            redirect: '',
            fetchUser: true,
        },
        fetchData: {
            url: process.env.VUE_APP_API_URL + 'auth/me',
        },
        refreshData: {
            url: process.env.VUE_APP_API_URL + 'auth/me',
        },
    },
})
