import axios from 'axios'

export default {
    data() {
        return {}
    },
    methods: {
        isAddressValid(address) {
            let count = 0
            address.address_components.forEach((component) => {
                if (
                    component.types.includes('locality') ||
                    component.types.includes('postal_town') ||
                    component.types.includes('country') ||
                    component.types.includes('postal_code') ||
                    component.types.includes('route')
                ) {
                    count++
                }
            })
            return count >= 4
        },

        isAddressComplete(address) {
            let count = 0
            address.address_components.forEach((component) => {
                if (
                    component.types.includes('street_number') ||
                    component.types.includes('route') ||
                    component.types.includes('locality') ||
                    component.types.includes('country') ||
                    component.types.includes('postal_code')
                ) {
                    count++
                }
            })
            return count >= 5
        },

        buildApiDomicileObject(domicile, additional_information) {
            let domicile_model = {}

            if (domicile.id_domicile) {
                domicile_model = {
                    id_domicile: domicile.id_domicile,
                }
            } else {
                domicile_model = {
                    country: '',
                    reference: '',
                    country_code: '',
                    latitude: '',
                    locality: '',
                    longitude: '',
                    postal_code: undefined,
                    route: '',
                    street_number: '',
                    address2: !domicile.formatted_address.includes(domicile.name) ? domicile.name : '',
                }

                let components = domicile.address_components
                components.forEach((component) => {
                    if (component.types.includes('locality') || component.types.includes('postal_town')) {
                        domicile_model.locality = component.long_name
                    } else if (component.types.includes('country')) {
                        domicile_model.country = component.long_name
                        domicile_model.country_code = component.short_name
                    } else if (component.types.includes('postal_code')) {
                        domicile_model.postal_code = component.long_name
                    }
                })

                if (this.isAddressComplete(domicile)) {
                    components.forEach((component) => {
                        if (component.types.includes('street_number')) {
                            domicile_model.street_number = component.long_name
                        } else if (component.types.includes('route')) {
                            domicile_model.route = component.long_name
                        }
                    })
                } else {
                    domicile_model.route = domicile.formatted_address.substring(0, domicile.formatted_address.indexOf(','))
                }

                if (typeof domicile.geometry.location.lat === 'number') {
                    //Google map
                    domicile_model.latitude = domicile.geometry.location.lat
                    domicile_model.longitude = domicile.geometry.location.lng
                } else {
                    //Google autocomplete
                    domicile_model.latitude = domicile.geometry.location.lat()
                    domicile_model.longitude = domicile.geometry.location.lng()
                }
            }

            return {...domicile_model, ...additional_information}
        },
    },
}
