import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"add-asset-liquid-dialog-component"}},[_c(VDialog,{attrs:{"max-width":"600"},model:{value:(_vm.dialog_add_asset_liquid),callback:function ($$v) {_vm.dialog_add_asset_liquid=$$v},expression:"dialog_add_asset_liquid"}},[_c(VCard,{attrs:{"id":"add-asset-liquid-dialog"}},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.$t('edit_equipment.add_new_liquid'))+" ")]),_c(VCardText,{staticClass:"mt-4 pb-0"},[_c(VForm,{ref:"form_add_asset_liquid"},[_c(VTextField,{attrs:{"label":_vm.$t('edit_equipment.liquid_name'),"rules":[
                            function (v) { return !!v || this$1.$t('edit_equipment.require_label'); },
                            function (v) { return v.length < 50 || this$1.$t('edit_equipment.too_long_label'); } ],"filled":""},model:{value:(_vm.asset_liquid.label),callback:function ($$v) {_vm.$set(_vm.asset_liquid, "label", $$v)},expression:"asset_liquid.label"}}),_c('color-picker-component',{on:{"confirmLiquidChoose":_vm.confirmLiquidChoose}}),_c(VTextField,{attrs:{"label":_vm.$t('edit_equipment.density'),"rules":[function (v) { return !!v || this$1.$t('edit_equipment.require_density'); }],"filled":"","type":"number"},model:{value:(_vm.asset_liquid.density),callback:function ($$v) {_vm.$set(_vm.asset_liquid, "density", $$v)},expression:"asset_liquid.density"}}),_c(VTextField,{attrs:{"label":_vm.$t('edit_equipment.code_article'),"filled":""},model:{value:(_vm.asset_liquid.code_article),callback:function ($$v) {_vm.$set(_vm.asset_liquid, "code_article", $$v)},expression:"asset_liquid.code_article"}})],1)],1),_c(VContainer,{staticClass:"px-6"},[_c(VBtn,{staticClass:"validation-button",attrs:{"loading":_vm.loading_add_asset_liquid,"block":"","color":"primary","large":""},on:{"click":function($event){$event.preventDefault();return _vm.addNewAssetLiquid()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.dialog_add_asset_liquid = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }