import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/asset'

export default {
    getAssetImages(idAsset: number): Promise<AxiosResponse<IServerResponse<string[]>>> {
        return axios.get(`${resource}/${idAsset}/images`)
    },

    getAssetByDeviceNumber(deviceNumber: string): Promise<AxiosResponse<IServerResponse<Array<any>>>> {
        return axios.get(`/api/v1/device/${deviceNumber}/asset`)
    },

    postAssetImages(idAsset: number, images): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/${idAsset}/image`, images, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },

    putAssociateAssetToDomicile(id_asset: number, id_domicile: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${id_asset}/domicile/${id_domicile}/associate`)
    },

    postAssociateAssetToClient(id_asset: number, id_client: number, id_domicile: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/${id_asset}/client/${id_client}/associate`, { id_domicile: id_domicile })
    },

    putDissociateAssetToClient(id_asset: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${id_asset}/dissociate`)
    },

    putEditAsset(id_asset, asset, domicile): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${id_asset}`, { asset: asset, domicile: domicile })
    },

    deleteAssetImage(idAsset: number, imageName: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.delete(`${resource}/${idAsset}/image?name=${imageName}`)
    },

    updateAssetV2(id_asset: number, updated_asset: any): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/v2/${id_asset}`, updated_asset)
    },

    putAssetLocalization(
        id_asset: number,
        localisation: {
            latitude: number
            longitude: number
        },
    ): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${id_asset}/localisation`, localisation)
    },
}
