import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = 'api/v1/brand_reference'

export default {
    geBrandReference(): Promise<AxiosResponse<IServerResponse<Array<IBrandReference>>>> {
        return axios.get(resource, { params: { type: 'CUVE' } })
    },
}
