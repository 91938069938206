import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dissociate-client-to-asset-dialog-component"}},[_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.is_dissociate_client_to_asset_dialog_open),callback:function ($$v) {_vm.is_dissociate_client_to_asset_dialog_open=$$v},expression:"is_dissociate_client_to_asset_dialog_open"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('dissociate_client_to_asset_dialog_component.dissociate_client')))]),_c(VCardText,[(_vm.asset.client)?_c('p',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('dissociate_client_to_asset_dialog_component.will_dissociate_client_to_equipment', [
                            _vm.asset.client.client_space_name,
                            _vm.asset.name ])
                    )}}):_vm._e(),_c(VBtn,{attrs:{"loading":_vm.dissociating_asset_to_client,"block":"","color":"error","large":""},on:{"click":function($event){$event.preventDefault();return _vm.putDissociateAssetToClient()}}},[_vm._v(" "+_vm._s(_vm.$t('global.confirm'))+" ")]),_c(VBtn,{staticClass:"cancel-btn mt-1",attrs:{"block":"","large":"","text":""},on:{"click":function($event){_vm.is_dissociate_client_to_asset_dialog_open = false}}},[_vm._v(" "+_vm._s(_vm.$t('global.cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }