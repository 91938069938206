import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/client'

export default {
    getClientDomiciles(id_client: number): Promise<AxiosResponse<IServerResponse<Array<IDomicile>>>> {
        return axios.get(`${resource}/${id_client}/domiciles`)
    },

    addClient(client: any): Promise<AxiosResponse<IServerResponse<IClient>>> {
        return axios.post(`${resource}`, client)
    },

    editClient(id_client: number, client: any): Promise<AxiosResponse<IServerResponse<IClient>>> {
        return axios.put(`${resource}/${id_client}`, client)
    },
}
