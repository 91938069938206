<template>
    <div id="autocomplete-component">
        <input :id="autocomplete_uid" class="autocomplete-input" />
    </div>
</template>
<script>
import { uid } from 'uid'

export default {
    name: 'AutocompleteComponent',
    data() {
        return {
            autocomplete_uid: `autocomplete-input-${uid()}`,
            autocomplete: {},
        }
    },
    methods: {
        updateInput(text) {
            const input = document.getElementById(this.autocomplete_uid)
            input.value = text
        }
    },
    mounted() {
        const input = document.getElementById(this.autocomplete_uid)
        this.autocomplete = new google.maps.places.Autocomplete(input)

        this.autocomplete.addListener('place_changed', () => {
            this.$emit('change:address', this.autocomplete.getPlace())
        })
    },
}
</script>

<style scoped>
.autocomplete-input {
    width: 100%;
    outline: none;
    background: #f0f0f0;
    border-bottom: 2px solid #cccccc;
    margin: 0;

    &:focus {
        border-bottom: 2px solid #1ba0db;
    }
}
</style>
