const state = () => ({
    show_snackbar: false,
    color_snackbar: '',
    text_snackbar: '',
})

const mutations = {
    showSnackbar(state, payload) {
        state.show_snackbar = true
        state.color_snackbar = payload.color
        state.text_snackbar = payload.text
        setTimeout(function () {
            state.show_snackbar = false
        }, 2000)
    },

    hideSnackbar(state) {
        state.show_snackbar = false
    },
}

const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
