<template>
    <div id="associate-client-widget-component">
        <widget-wrapper-component :title="$t('associate_client_widget_component.associate_client')" icon="mdi-account">
            <template v-slot:options>
                <menu-actions-component
                    v-if="asset.client"
                    :actions="[
                        {
                            title: $t('add_edit_client_dialog_component.edit_client'),
                            icon: 'mdi-pencil-outline',
                            emit: 'openEditClientDialog',
                            hasAccess: true,
                        },
                        {
                            title: $t('dissociate_client_to_asset_dialog_component.dissociate_client'),
                            icon: 'mdi-close',
                            emit: 'openDissociateClientToAssetDialog',
                            hasAccess: true,
                            color: 'error',
                        },
                    ]"
                    v-on:openDissociateClientToAssetDialog="openDissociateClientToAssetDialog()"
                    v-on:openEditClientDialog="openEditClientDialog()"
                />
            </template>

            <template v-slot:default>
                <div v-if="asset.client" class="d-flex flex-column gap-2">
                    <template v-for="(information, index) in information_to_display">
                        <div :key="index" class="d-flex gap-2 align-center">
                            <v-icon class="text-skin-light">{{ information.icon }}</v-icon>
                            <div>
                                <p class="mb-0 font-12">{{ information.title }}</p>
                                <p class="font-14 font-weight-medium mb-0">
                                    {{ asset.client[information.data] ? asset.client[information.data] : '-' }}
                                </p>
                            </div>
                        </div>
                    </template>
                </div>

                <div v-else>
                    <p class="mb-2">
                        {{ $t('associate_client_widget_component.no_client_associate') }}
                    </p>
                    <v-btn block class="validation-button" color="primary" @click="openAssociateClientToAssetDialog">
                        {{ $t('associate_client_widget_component.associate_client') }}
                    </v-btn>
                </div>
            </template>
        </widget-wrapper-component>

        <add-edit-client-dialog-component ref="addEditClientDialog" v-on:updated:client="$emit('refresh:form')" />
        <dissociate-client-to-asset-dialog-component ref="dissociateClientToAssetDialog" v-on:dissociated:client="$emit('refresh:form')" />
        <associate-client-to-asset-dialog-component ref="associateClientToAssetDialog" v-on:associated:client="$emit('refresh:form')" />
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'
import MenuActionsComponent from '@/components/MenuActionsComponent.vue'
import DissociateClientToAssetDialogComponent from '@/components/client/DissociateClientToAssetDialogComponent.vue'
import AssociateClientToAssetDialogComponent from '@/components/client/AssociateClientToAssetDialogComponent.vue'
import AddEditClientDialogComponent from '@/components/client/AddEditClientDialogComponent.vue'

export default {
    name: 'AssociateClientWidgetComponent',
    components: {
        AddEditClientDialogComponent,
        AssociateClientToAssetDialogComponent,
        DissociateClientToAssetDialogComponent,
        MenuActionsComponent,
        WidgetWrapperComponent,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            information_to_display: [
                {
                    icon: 'mdi-office-building',
                    title: this.$t('associate_client_widget_component.company_name'),
                    data: 'company_name',
                    clickable: true,
                },
                {
                    icon: 'mdi-home',
                    title: this.$t('associate_client_widget_component.client_space_name'),
                    data: 'client_space_name',
                    clickable: true,
                },
                {
                    icon: 'mdi-card-account-details',
                    title: this.$t('associate_client_widget_component.siren'),
                    data: 'siret',
                    clickable: false,
                },
                {
                    icon: 'mdi-briefcase',
                    title: this.$t('associate_client_widget_component.client_reference'),
                    data: 'code_client',
                    clickable: false,
                },
            ],
        }
    },
    methods: {
        openDissociateClientToAssetDialog() {
            this.$refs.dissociateClientToAssetDialog.openDissociateClientToAssetDialog(this.asset)
        },

        openAssociateClientToAssetDialog() {
            this.$refs.associateClientToAssetDialog.openAssociateClientToAssetDialog(this.asset)
        },

        openEditClientDialog() {
            this.$refs.addEditClientDialog.openEditClientDialog(this.asset.client)
        },
    },
}
</script>
