<template>
    <div id="asset-summary-component">
        <widget-wrapper-component :title="$t('asset_summary_card_component.equipment_information')" is-already-open>
            <template v-slot:default>
                <div class="d-flex flex-column gap-1">
                    <div v-if="module && module.name" class="d-flex align-center gap-1 pl-n2 flex-wrap">
                        <custom-icon-component :icon="module.menu_icon" default-icon="mdi-remote" style="width: 24px; height: 24px" />
                        <span class="text-no-wrap">{{ $t('asset_summary_component.asset_type') }}:</span>
                        <span class="font-weight-medium">
                            {{ module.name }}
                        </span>
                    </div>

                    <div v-if="asset.internal_reference" class="d-flex align-center gap-1 flex-wrap">
                        <v-icon>mdi-tag</v-icon>
                        <span class="text-no-wrap">{{ $t('asset_summary_component.internal_reference') }}:</span>
                        <span class="font-weight-medium">
                            {{ asset.internal_reference }}
                        </span>
                    </div>

                    <div v-if="asset.installation_date" class="d-flex align-center gap-1 flex-wrap">
                        <v-icon>mdi-calendar</v-icon>
                        <span class="text-no-wrap">{{ $t('asset_summary_component.installation_date') }}:</span>
                        <span class="font-weight-medium">
                            {{ asset.installation_date }}
                        </span>
                    </div>

                    <div v-if="asset.client" class="d-flex align-center gap-1 flex-wrap">
                        <v-icon>mdi-account</v-icon>
                        <span class="text-no-wrap">{{ $t('asset_summary_component.associated_client') }}:</span>
                        <span class="font-weight-medium">
                            {{ asset.client.client_space_name }}
                        </span>
                    </div>

                    <div v-if="asset.domicile" class="d-flex align-center gap-1 flex-wrap">
                        <v-icon>mdi-map-marker-outline</v-icon>
                        <span class="text-no-wrap">{{ $t('asset_summary_component.address') }}:</span>
                        <span class="font-weight-medium">
                            {{ displayToHumanDomicileAddress(asset.domicile) }}
                        </span>
                    </div>

                    <div v-if="asset.contract_number" class="d-flex align-center gap-1 flex-wrap">
                        <v-icon>mdi-book-outline</v-icon>
                        <span class="text-no-wrap">{{ $t('asset_summary_component.contract_number') }}:</span>
                        <span class="font-weight-medium">
                            {{ asset.contract_number }}
                        </span>
                    </div>
                </div>
            </template>
        </widget-wrapper-component>
    </div>
</template>

<script>
import CustomIconComponent from '@/components/CustomIconComponent.vue'
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'

export default {
    name: 'AssetSummaryComponent',
    components: { WidgetWrapperComponent, CustomIconComponent },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        module: {
            type: Object,
            required: false,
        },
    },
    methods: {
        displayToHumanDomicileAddress(domicile) {
            return `${domicile.address_2 !== '' ? domicile.address_2 : ''} ${domicile.street_number ? domicile.street_number : ''} ${
                domicile.address
            },
            ${domicile.postal_code} ${domicile.city}`
        },
    },
}
</script>

<style scoped>
.border-import-file {
    border-style: dashed;
    border-width: 2px;
}
</style>
