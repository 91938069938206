<template>
    <div id="manage-stock">
        <v-container>
            <Header :title="$t('manage_stock.list_devices_emitting')" />

            <v-row class="ma-0">
                <v-col align="end" cols="12">
                    <v-btn class="validation-button" @click="dialog_mass_reset_device = true">
                        {{ $t('manage_stock.mass_reset') }}
                    </v-btn>
                </v-col>
            </v-row>

            <v-row class="ma-0 mb-4">
                <v-col class="py-2" cols="12" lg="3" sm="6">
                    <card-value-subtitle-component :subtitle="$t('manage_stock.devices_in_stock')" :value="total_devices_in_stock" />
                </v-col>

                <v-col class="py-2" cols="12" lg="3" sm="6">
                    <card-value-subtitle-component :subtitle="$t('manage_stock.devices_in_return')" :value="total_devices_in_return" />
                </v-col>

                <v-col class="py-2" cols="12" lg="3" sm="6">
                    <card-value-subtitle-component
                        :subtitle="$t('manage_stock.devices_in_transition')"
                        :value="total_devices_in_transition"
                    />
                </v-col>

                <v-col class="py-2" cols="12" lg="3" sm="6">
                    <card-value-subtitle-component
                        :subtitle="$t('manage_stock.devices_in_prototypage')"
                        :value="total_devices_in_prototypage"
                    />
                </v-col>
            </v-row>

            <v-row align="end" class="ma-0" justify="end">
                <v-col cols="12" lg="5">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('manage_stock.research')"
                        clearable
                        elevation="0"
                        flat
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                    />
                </v-col>
            </v-row>

            <v-row class="ma-0">
                <v-col col="12">
                    <v-toolbar class="pt-2" elevation="0">
                        <v-toolbar-title>
                            <p class="mb-n2">
                                {{ $t('manage_stock.devices_in_stock_emitting') }}
                            </p>

                            <span class="font-12 mt-4 text-skin-medium">
                                {{ $t('manage_stock.devices_in_stock_emitting_advice') }}
                            </span>
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-data-table
                        :footer-props="{
                            'items-per-page-options': [5, 10, 25, 50],
                        }"
                        :headers="headers"
                        :items="devices"
                        :loading="loading_device"
                        :server-items-length="total_devices"
                        :sort-by="order_by.key"
                        :sort-desc="order_by.order"
                        v-on:update:options="handleOptions"
                    >
                        <template v-slot:item.path_image_device_referentiel="{ item }">
                            <v-img :src="item.path_image_device_referentiel" class="my-1" contain max-height="50" max-width="50" />
                        </template>

                        <template v-slot:item.numero_device="{ item }">
                            <a
                                class="text-uppercase text-decoration-underline"
                                @click="
                                    $router.push({
                                        name: 'check_network',
                                        params: {
                                            device_number: item.device_number,
                                        },
                                    })
                                "
                            >
                                {{ item.device_number }}
                            </a>
                        </template>

                        <template v-slot:item.date_last_seen="{ item }">
                            <chips-last-statement :danger="345600" :date="item.date_last_emission" :warning="172800" class="ml-1" />
                        </template>

                        <template v-slot:item.shutdown_method="{ item }">
                            <span>{{ $t(`manage_stock.${item.shutdown_method}`) }}</span>
                        </template>

                        <template v-slot:item.downlink="{ item }">
                            <v-icon
                                v-if="item.shutdown_method === 'DOWNLINK'"
                                class="pr-2 pointer"
                                @click="
                                    $router.push({
                                        name: 'send_downlink',
                                        params: {
                                            device_number: item.device_number,
                                        },
                                    })
                                "
                            >
                                mdi-open-in-new
                            </v-icon>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-icon class="pr-2 pointer" @click="prepareResetDevice(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog_reset_device" max-width="600">
                <v-card>
                    <v-card-title>
                        {{ `${$t('manage_stock.reset_device')} ${device_to_reset.device_number}` }}
                    </v-card-title>

                    <v-card-text class="pt-2 pb-4">
                        {{ $t('manage_stock.reset_device_informations') }}
                    </v-card-text>

                    <v-container class="px-6">
                        <v-btn :loading="loading_reset_device" block class="validation-button" @click="resetDevice()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-2" text @click="dialog_reset_device = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-container>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_mass_reset_device" max-width="600">
                <v-card>
                    <v-card-title>
                        {{ $t('manage_stock.mass_reset_device') }}
                    </v-card-title>

                    <v-card-text class="pt-2 pb-4">
                        {{ $t('manage_stock.mass_reset_device_informations') }}
                    </v-card-text>

                    <v-container class="px-6">
                        <v-btn :loading="loading_mass_reset_device" block class="v-btn validation-button" @click="massResetDevices()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-2" text @click="dialog_mass_reset_device = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import moment from '../../plugins/moment'
import Header from '@/components/Header.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import UserRepository from '@/repositories/UserRepository'
import DeviceInput from '@/components/DeviceInput.vue'
import ChipsLastStatement from '@/components/ChipsLastStatement.vue'
import CardValueSubtitleComponent from '@/components/CardValueSubtitleComponent.vue'

export default {
    components: { CardValueSubtitleComponent, ChipsLastStatement, DeviceInput, Header },
    data() {
        return {
            loading_device: false,
            headers: [
                { text: this.$t('manage_stock.id_device'), value: 'id_device' },
                { text: this.$t('manage_stock.icon'), value: 'path_image_device_referentiel', sortable: false },
                { text: this.$t('manage_stock.device_number'), value: 'numero_device' },
                { text: this.$t('manage_stock.device_date_last_seen'), value: 'date_last_seen' },
                { text: this.$t('manage_stock.reset_device_raz'), value: 'actions', sortable: false },
                { text: this.$t('manage_stock.method_reset_device'), value: 'shutdown_method', sortable: false },
                { text: 'Downlink', value: 'downlink', sortable: false },
            ],
            devices: [],
            total_devices: 0,
            search: '',

            total_devices_in_stock: 0,
            total_devices_in_return: 0,
            total_devices_in_transition: 0,
            total_devices_in_prototypage: 0,

            dialog_reset_device: false,
            device_to_reset: {},
            loading_reset_device: false,

            dialog_mass_reset_device: false,
            loading_mass_reset_device: false,
            limit: 10,
            offset: 0,
            order_by: {
                key: '',
                order: false,
            },
        }
    },
    created() {
        this.getDevicesInventoryOfAdminUser()
        this.getDeviceByIdUserAdminDatatable()
    },
    watch: {
        search() {
            if (this.search === null || this.search === '' || this.search.length >= 3) {
                this.getDeviceByIdUserAdminDatatable()
            }
        },
    },
    methods: {
        moment,
        getDevicesInventoryOfAdminUser() {
            UserRepository.getDevicesInventoryOfAdminUser()
                .then((response) => {
                    response.data.data.forEach((totalDeviceByState) => {
                        switch (parseInt(totalDeviceByState.is_in_stock)) {
                            case -6:
                                this.total_devices_in_transition = totalDeviceByState.total
                                break
                            case -4:
                                this.total_devices_in_return = totalDeviceByState.total
                                break
                            case 1:
                                this.total_devices_in_stock = totalDeviceByState.total
                                break
                            case 2:
                                this.total_devices_in_prototypage = totalDeviceByState.total
                                break
                        }
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getDeviceByIdUserAdminDatatable() {
            let pagination = `limit=${this.limit}&offset=${this.offset}&orders=`
            if (typeof this.order_by.key !== 'undefined' && this.order_by.key !== '') {
                pagination += this.order_by.key
                if (this.order_by.order === true) {
                    pagination += ':desc'
                } else {
                    pagination += ':asc'
                }
            }

            this.loading_device = true
            DeviceRepository.getDeviceByIdUserAdminAndIsInStockDatatable(1, this.search === null ? '' : this.search, pagination)
                .then((response) => {
                    this.devices = response.data.data.data
                    this.total_devices = response.data.data.total_rows
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_device = false
                })
        },

        handleOptions(options) {
            this.order_by.order = options.sortDesc[0]
            this.order_by.key = options.sortBy[0]
            this.limit = options.itemsPerPage
            this.offset = options.itemsPerPage * (options.page - 1)

            this.getDeviceByIdUserAdminDatatable()
        },

        prepareResetDevice(device) {
            this.device_to_reset = device
            this.dialog_reset_device = true
        },

        resetDevice() {
            this.loading_reset_device = true

            DeviceRepository.postResetDevice(this.device_to_reset.id_device)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('manage_stock.device_reset_successfully'),
                    })
                    this.getDeviceByIdUserAdminDatatable()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.dialog_reset_device = false
                    this.loading_reset_device = false
                    this.device_to_reset = {}
                })
        },

        massResetDevices() {
            this.loading_mass_reset_device = true
            DeviceRepository.postMassResetEmittingInStockDevices()
                .then(() => {
                    this.dialog_mass_reset_device = false
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('manage_stock.raz_mass_devices_success'),
                    })
                    this.getDeviceByIdUserAdminDatatable()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_mass_reset_device = false
                })
        },
    },
}
</script>
