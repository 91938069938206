import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { IDownlink } from '@/models/IDownlink'

const resource = '/api/v1/downlink'

export default {
    getDefaultDownlinkByIdDeviceReferential(idDeviceReferential: number): Promise<AxiosResponse<IServerResponse<any[]>>> {
        return axios.get(`${resource}/device_referential/${idDeviceReferential}`)
    },

    postAddNewDownlink(idDevice: number, downlink: IDownlink): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/device/${idDevice}`, downlink)
    },

    putUpdateDownlink(idDownlink: number, downlink: IDownlink): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idDownlink}`, downlink)
    },

    deleteDownlink(idDownlink: number): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.delete(`${resource}/${idDownlink}`)
    }
}
