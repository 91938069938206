<template>
    <div id="localisation-asset-widget-component">
        <widget-wrapper-component :title="$t('localisation_asset_widget_component.asset_localisation')" icon="mdi-home" is-already-open>
            <template v-slot:options>
                <menu-actions-component
                    :actions="[
                        {
                            title: $t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation'),
                            icon: 'mdi-swap-horizontal',
                            emit: 'openEditAssetLocalisationDialog',
                            hasAccess: true,
                        },
                    ]"
                    v-on:openEditAssetLocalisationDialog="openEditAssetLocalisationDialog()"
                />
            </template>

            <template v-slot:default>
                <div v-if="asset.latitude && asset.longitude">
                    <map-coordinate-component :coordinate="{ latitude: asset.latitude, longitude: asset.longitude }" readonly />
                </div>

                <div v-else>
                    <p class="mb-1">
                        {{ $t('localisation_asset_widget_component.no_location_for_this_equipment') }}
                    </p>
                    <v-btn class="validation-button mb-2" color="primary" @click="openEditAssetLocalisationDialog()">
                        <v-icon class="mr-1" dense>mdi-crosshairs-gps</v-icon>
                        {{ $t('edit_asset_localisation_dialog_equipment.edit_equipment_localisation') }}
                    </v-btn>
                </div>
            </template>
        </widget-wrapper-component>

        <edit-asset-localisation-dialog-component ref="editAssetLocalisationDialog" v-on:refresh:form="$emit('refresh:form')" />
    </div>
</template>

<script>
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'
import MenuActionsComponent from '@/components/MenuActionsComponent.vue'
import EditAssetLocalisationDialogComponent from '@/components/EditAssetLocalisationDialogComponent.vue'
import MapCoordinateComponent from '@/components/site/MapCoordinateComponent.vue'

export default {
    name: 'localisation-asset-widget-component',
    components: {
        MapCoordinateComponent,
        EditAssetLocalisationDialogComponent,
        MenuActionsComponent,
        WidgetWrapperComponent,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openEditAssetLocalisationDialog() {
            this.$refs.editAssetLocalisationDialog.openEditAssetLocalisationDialog(this.asset)
        },
    },
}
</script>
