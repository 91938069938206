<template>
    <div id="edit-asset-of-asset-module-form-component">
        <template v-if="!fetchingLiquids">
            <div>
                <div class="d-flex flex-column gap-4">
                    <asset-summary-component :asset="asset" :module="module" />
                    <manage-asset-image-component :asset="asset" />

                    <div id="edit-asset-of-asset-module-form">
                        <widget-wrapper-component :title="$t('edit_asset_of_asset_module_form_component.asset')" is-already-open>
                            <template v-slot:icon>
                                <custom-icon-component :icon="module.menu_icon" default-icon="mdi-remote" />
                            </template>

                            <template v-slot:default>
                                <v-form ref="form" class="mb-4">
                                    <v-text-field
                                        v-model="asset.code_asset"
                                        :label="$t('edit_asset_of_asset_module_form_component.ref_equipment')"
                                        filled
                                    />

                                    <v-text-field
                                        v-model="asset.name"
                                        :label="$t('edit_asset_of_asset_module_form_component.name_equipment')"
                                        :rules="[(v) => !!v || this.$t('generic_form_component.required_fields')]"
                                        filled
                                    />

                                    <v-row class="mb-2">
                                        <v-col
                                            v-for="index in module.total_models"
                                            :lg="module.total_models === 1 ? '12' : '3'"
                                            :md="module.total_models === 1 ? '12' : '6'"
                                            cols="12"
                                        >
                                            <p class="mb-0 font-weight-medium text-uppercase">{{ module.name }} {{ index }}</p>

                                            <AssetModelFormWrapperComponent
                                                :asset_liquids="asset_liquids"
                                                :asset_module="module"
                                                :column="index - 1"
                                                :configuration="configuration[index - 1]"
                                                v-on:update:model="handleModelConfigurationUpdate"
                                                :authorize_config_selection="!assetHasOneModelOnly"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-btn :loading="editing_asset" block class="validation-button" large @click="prepareUpdate">
                                        {{ $t('global.validate') }}
                                    </v-btn>
                                </v-form>
                            </template>
                        </widget-wrapper-component>
                    </div>

                    <associate-client-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
                    <associate-site-to-asset-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
                    <localisation-asset-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
                    <manage-note-admin-device-widget-component :device_number="device_number" />
                </div>
            </div>
        </template>

        <template v-else>
            <v-skeleton-loader type="image" />
        </template>
    </div>
</template>

<script>
import ModuleRepository from '@/repositories/ModuleRepository'
import AssetLiquidRepository from '@/repositories/AssetLiquidRepository'
import AssetModelFormWrapperComponent from '@/components/AssetModelFormWrapperComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import ManageAssetImageComponent from '@/components/ManageAssetImageComponent.vue'
import ManageNoteAdminDeviceWidgetComponent from '@/components/ManageNoteAdminDeviceWidgetComponent.vue'
import AssetSummaryComponent from '@/components/AssetSummaryComponent.vue'
import AssociateClientWidgetComponent from '@/components/client/AssociateClientWidgetComponent.vue'
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'
import AssociateSiteToAssetWidgetComponent from '@/components/site/AssociateSiteToAssetWidgetComponent.vue'
import CustomIconComponent from '@/components/CustomIconComponent.vue'
import LocalisationAssetWidgetComponent from '@/components/LocalisationAssetWidgetComponent.vue'
import AddEditClientDomicileDialogComponent from '@/components/site/AddEditClientDomicileDialogComponent.vue'

export default {
    name: 'EditAssetOfAssetModuleFormComponent',
    components: {
        AddEditClientDomicileDialogComponent,
        LocalisationAssetWidgetComponent,
        CustomIconComponent,
        AssociateSiteToAssetWidgetComponent,
        WidgetWrapperComponent,
        AssociateClientWidgetComponent,
        AssetSummaryComponent,
        ManageNoteAdminDeviceWidgetComponent,
        ManageAssetImageComponent,
        AssetModelFormWrapperComponent,
    },
    data() {
        return {
            asset: {},
            device_number: '',
            module: {},
            asset_liquids: [],
            configuration: [],
            fetchingModule: true,
            fetchingLiquids: true,
            is_equipment_widget_open: true,
            models_configurations: [],

            editing_asset: false,
        }
    },
    methods: {
        receiveDataFromDeviceInput(data, device_number) {
            this.asset = data.asset
            this.device_number = device_number
            this.configuration = []
            if (JSON.parse(this.asset.configuration)) {
                JSON.parse(this.asset.configuration).forEach((config) => {
                    this.configuration.push(config)
                })
                if (this.assetHasOneModelOnly) {
                    this.configuration[0].is_required = true
                }
            }
            this.fetchAssetModuleById()
        },

        fetchAssetModuleById() {
            this.fetchingModule = true
            ModuleRepository.getModuleById(this.asset.id_module)
                .then((success) => {
                    this.module = success.data.data
                    this.fetchAssetLiquidByIdModule()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetchingModule = false
                })
        },

        fetchAssetLiquidByIdModule() {
            this.fetchingLiquids = true
            AssetLiquidRepository.getAssetLiquidByIdModule(this.asset.id_professional, this.module.id_module)
                .then((success) => {
                    this.asset_liquids = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetchingLiquids = false
                })
        },

        handleModelConfigurationUpdate(data) {
            this.models_configurations[data.column] = data.model_configuration
        },

        async prepareUpdate() {
            const valid = await this.$refs.form.validate()
            if (valid) {
                this.editing_asset = true
                let updatedAsset = {
                    asset_models: [],
                    asset_name: this.asset.name,
                    asset_code: this.asset.code_asset,
                }

                //Get all columns require
                let emptyColumns = Array.from(Array(this.module.total_models).keys())

                this.models_configurations.forEach((model_configuration, column) => {
                    //Push model
                    updatedAsset.asset_models.push({
                        is_required: model_configuration.is_required,
                        id_asset_model: model_configuration.id_asset_model,
                        id_asset_liquid: model_configuration.id_asset_liquid,
                        configuration: model_configuration.configuration,
                    })

                    //Delete already defined column
                    emptyColumns.splice(
                        emptyColumns.findIndex((index) => index === column),
                        1,
                    )
                })

                //Fill empty columns to avoid error on nb model require
                emptyColumns.forEach((column) => {
                    updatedAsset.asset_models.splice(column, 0, {
                        is_required: false,
                    })
                })

                this.update(updatedAsset)
            }
        },

        update(updatedAsset) {
            AssetRepository.updateAssetV2(this.asset.id_asset, updatedAsset)
                .then(() => {
                    this.$store.commit('successAlert/showSuccessAlert', true)
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('edit_equipment.equipment_associate_with_device_updated', {
                            asset: this.asset.name,
                            device: this.device_number,
                        }),
                        value_bottom: '',
                        type: 'success',
                    })
                    this.$store.commit('successAlert/setListeningDevice', this.device_number)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.editing_asset = false
                })
        },
    },
    computed: {
        assetHasOneModelOnly() {
            return (this.configuration) ? (this.configuration.length === 1) : false
        },
    },
}
</script>

<style>
.white-input .v-input__slot {
    background-color: white !important;
    border: white !important;
}
</style>
