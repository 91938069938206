import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"associate-client-widget-component"}},[_c('widget-wrapper-component',{attrs:{"title":_vm.$t('associate_client_widget_component.associate_client'),"icon":"mdi-account"},scopedSlots:_vm._u([{key:"options",fn:function(){return [(_vm.asset.client)?_c('menu-actions-component',{attrs:{"actions":[
                    {
                        title: _vm.$t('add_edit_client_dialog_component.edit_client'),
                        icon: 'mdi-pencil-outline',
                        emit: 'openEditClientDialog',
                        hasAccess: true,
                    },
                    {
                        title: _vm.$t('dissociate_client_to_asset_dialog_component.dissociate_client'),
                        icon: 'mdi-close',
                        emit: 'openDissociateClientToAssetDialog',
                        hasAccess: true,
                        color: 'error',
                    } ]},on:{"openDissociateClientToAssetDialog":function($event){return _vm.openDissociateClientToAssetDialog()},"openEditClientDialog":function($event){return _vm.openEditClientDialog()}}}):_vm._e()]},proxy:true},{key:"default",fn:function(){return [(_vm.asset.client)?_c('div',{staticClass:"d-flex flex-column gap-2"},[_vm._l((_vm.information_to_display),function(information,index){return [_c('div',{key:index,staticClass:"d-flex gap-2 align-center"},[_c(VIcon,{staticClass:"text-skin-light"},[_vm._v(_vm._s(information.icon))]),_c('div',[_c('p',{staticClass:"mb-0 font-12"},[_vm._v(_vm._s(information.title))]),_c('p',{staticClass:"font-14 font-weight-medium mb-0"},[_vm._v(" "+_vm._s(_vm.asset.client[information.data] ? _vm.asset.client[information.data] : '-')+" ")])])],1)]})],2):_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('associate_client_widget_component.no_client_associate'))+" ")]),_c(VBtn,{staticClass:"validation-button",attrs:{"block":"","color":"primary"},on:{"click":_vm.openAssociateClientToAssetDialog}},[_vm._v(" "+_vm._s(_vm.$t('associate_client_widget_component.associate_client'))+" ")])],1)]},proxy:true}])}),_c('add-edit-client-dialog-component',{ref:"addEditClientDialog",on:{"updated:client":function($event){return _vm.$emit('refresh:form')}}}),_c('dissociate-client-to-asset-dialog-component',{ref:"dissociateClientToAssetDialog",on:{"dissociated:client":function($event){return _vm.$emit('refresh:form')}}}),_c('associate-client-to-asset-dialog-component',{ref:"associateClientToAssetDialog",on:{"associated:client":function($event){return _vm.$emit('refresh:form')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }