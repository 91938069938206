<template>
    <div id="color-picker-component">
        <div @click="dialog_pick_color = true">
            <v-text-field
                v-model="color"
                :background-color="color"
                :label="$t('color_picker_component.color')"
                class="text-white"
                filled
                color="white"
            />
        </div>

        <v-dialog v-model="dialog_pick_color" max-width="500px">
            <v-card>
                <v-card-title>
                    {{ $t('color_picker_component.content_color') }}
                </v-card-title>

                <v-card-text class="pt-4 pb-0">
                    <v-color-picker v-model="temporary_color" class="mx-auto pb-0" elevation="0" hide-inputs />
                </v-card-text>

                <v-container class="px-6">
                    <v-btn block class="validation-button" color="primary" large @click.prevent="confirmLiquidChoose()">
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="dialog_pick_color = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ColorPickerComponent',
    data() {
        return {
            color: '#CCCCCC',
            temporary_color: '#CCCCCC',
            dialog_pick_color: false,
        }
    },
    methods: {
        confirmLiquidChoose() {
            this.color = this.temporary_color
            this.$emit('confirmLiquidChoose', this.color)
            this.dialog_pick_color = false
        },
    },
}
</script>

<style>
.text-white input {
    color: white !important;
}

.text-white label {
    color: white !important;
}
</style>
