<template>
    <v-app id="app">
        <v-main>
            <loader v-if="this.$store.state.loader.show_loader" />
            <success-alert v-if="this.$store.state.successAlert.show_success_alert" />
            <v-snackbar v-model="this.$store.state.snackbar.show_snackbar" :color="this.$store.state.snackbar.color_snackbar">
                {{ this.$store.state.snackbar.text_snackbar }}
            </v-snackbar>

            <router-view class="mb-16" />

            <div class="display-version">
                <v-chip v-if="isLocalEnvironment() || isPrpEnvironment()" outlined small>
                    {{ isPrpEnvironment() ? $t('app.prp_environment') : $t('app.local_environment') }}
                </v-chip>

                <span class="font-10 text-decoration-underline font-weight-bold"> {{ $t('app.version') }} {{ app_version }} </span>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import Vue from 'vue'
import './scss/app.scss'
import Loader from '/src/components/Loader'
import SuccessAlert from '/src/components/SuccessAlert'
import { version } from '../package.json'

export default Vue.extend({
    name: 'App',
    components: { Loader, SuccessAlert },
    data() {
        return {
            registration: null,
        }
    },
    created() {
        this.$store.commit('snackbar/hideSnackbar')
        this.$store.commit('loader/showLoader')
        document.addEventListener('serviceWorkerUpdateEvent', this.updateAvailable, { once: true })
        document.addEventListener('logout', () => { this.$auth.logout() })
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload()
        })

        this.$auth.load().then(() => {
            this.hideLoader()
        })
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            }
        },

        isLocalEnvironment() {
            return !(this.isPrpEnvironment() || this.isProdEnvironment())
        },

        isPrpEnvironment() {
            return process.env.VUE_APP_API_URL === 'https://prp.api.quatre-factorielle.com/'
        },

        isProdEnvironment() {
            return process.env.VUE_APP_API_URL === 'https://api-pwa.quatre-factorielle.com/'
        },

        hideLoader() {
            if (
                this.$store.getters['general/application_version'] === version ||
                process.env.NODE_ENV === 'local' ||
                this.$store.getters['general/application_version'] === ''
            ) {
                this.$store.commit('loader/hideLoader')
            } else if (!this.$store.getters['general/application_version']) {
                //In case current service worker version is already set, but store has been clear
                this.$store.dispatch('general/setApplicationVersion', this.app_version)
                this.$store.commit('loader/hideLoader')
            } else {
                this.$store.dispatch('general/setApplicationVersion', this.app_version)
            }
        },
    },
})
</script>

<style>
#app {
    background-color: #f5f7fb;
}
</style>
