<template>
    <div id="edit-contact-on-site-dialog-component">
        <v-dialog v-model="is_edit_contact_on_site_dialog_open" max-width="600px">
            <v-card>
                <v-card-title>
                    {{ $t('edit_contact_on_site_component.edit_contact_on_site') }}
                </v-card-title>

                <v-card-text>
                    <v-text-field v-model="domicile.contact_last_name" :label="$t('edit_contact_on_site_component.last_name')" filled />
                    <v-text-field v-model="domicile.contact_first_name" :label="$t('edit_contact_on_site_component.first_name')" filled />
                    <v-text-field v-model="domicile.contact_mail" :label="$t('edit_contact_on_site_component.email')" filled type="email" />
                    <v-text-field v-model="domicile.contact_phone" :label="$t('edit_contact_on_site_component.phone')" filled type="tel" />
                    <v-textarea v-model="domicile.contact_note" :label="$t('edit_contact_on_site_component.note')" filled />

                    <v-btn
                        :loading="editing_contact_on_site"
                        block
                        class="validation-button"
                        large
                        @click.prevent="patchUpdateDomicileAndContactSite()"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="is_edit_contact_on_site_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DomicileRepository from '@/repositories/DomicileRepository'

export default {
    name: 'EditContactOnSiteDialogComponent',
    data() {
        return {
            domicile: {},
            is_edit_contact_on_site_dialog_open: false,
            editing_contact_on_site: false,
        }
    },
    methods: {
        openEditContactOnSiteDialog(domicile) {
            this.is_edit_contact_on_site_dialog_open = true
            this.domicile = { ...domicile }
        },
        patchUpdateDomicileAndContactSite() {
            this.editing_contact_on_site = true
            DomicileRepository.patchUpdateDomicileAndContactSite({
                id_domicile: this.domicile.id_domicile,
                domicile_name: this.domicile.address_name,
                contact_first_name: this.domicile.contact_first_name,
                contact_last_name: this.domicile.contact_last_name,
                contact_mail: this.domicile.contact_mail,
                contact_phone: this.domicile.contact_phone,
                contact_note: this.domicile.contact_note,
            })
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('edit_contact_on_site_component.contact_on_site_modified'),
                    })
                    this.$emit('refresh:form')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.editing_contact_on_site = false
                })
        },
    },
}
</script>
