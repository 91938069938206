<template>
    <div id="dashboard-card">
        <v-card :min-height="size === 'l' ? 150 : ''" class="rounded-xl dashboard-card shadow-lg" @click="redirection">
            <template v-if="size === 'l'">
                <v-card-text>
                    <div>
                        <v-row class="ma-0">
                            <v-col cols="6">
                                <v-icon color="blue" large>{{ icon }}</v-icon>
                            </v-col>
                            <v-col v-if="append_inner" class="d-flex justify-end" cols="6">
                                <v-chip class="white--text" :color="append_inner_color">{{ append_inner }}</v-chip>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="pl-2">
                        <p class="font-20 font-weight-medium mb-0">{{ title }}</p>
                        <span class="text">{{ text }}</span>
                    </div>
                </v-card-text>
            </template>

            <template v-else>
                <div class="px-2 py-4">
                    <div>
                        <v-row class="ma-0">
                            <v-col :cols="append_inner ? 9 : 12" class="py-0">
                                <div class="d-flex align-start gap-2">
                                    <v-icon class="pt-1" color="blue" small>{{ icon }}</v-icon>

                                    <div>
                                        <p class="font-14 font-weight-medium mb-0">{{ title }}</p>
                                        <p class="text mb-0">{{ text }}</p>
                                    </div>
                                </div>
                            </v-col>
                            <v-col v-if="append_inner" class="d-flex justify-end py-0" cols="3">
                                <v-chip class="white--text" :color="append_inner_color">{{ append_inner }}</v-chip>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'DashboardCard',
    props: {
        title: {
            type: String,
            require: true,
        },
        text: {
            type: String,
            require: true,
        },
        icon: {
            type: String,
            require: true,
        },
        link: {
            type: String,
            require: true,
        },
        append_inner: {
            type: String,
            require: false,
        },
        append_inner_color: {
            type: String,
            require: false,
            default: 'red'
        },
        size: {
            type: 'm' | 'l',
            require: false,
            default: 'l',
        },
    },
    methods: {
        redirection() {
            if (this.link === 'install_device') {
                window.open(process.env.VUE_APP_INSTALLATION_URL)
                return
            } else if (this.link === 'public_reboot_tekelek') {
                window.open('https://public.fourdata.io/rebootTekelek')
                return
            }
            this.$router.push(this.link)
        },
    },
}
</script>

<style>
.dashboard-card:hover {
    box-shadow: none !important;
    background: #eceff2 !important;
    translate: -2px 2px !important;
}
</style>
