<template>
    <div id="associate-device-to-admin-dialog-component">
        <v-dialog v-model="is_associate_device_to_admin_dialog_open" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('global.auto_associate_device') }}
                </v-card-title>

                <v-card-text>
                    <device-input
                        ref="deviceToAssociate"
                        :label="$t('send_downlink.device_number')"
                        class="mt-4 mb-4"
                        type="associate"
                        v-on:getDevice="newDeviceSelected"
                        v-on:reset="device_to_associate = null"
                        v-on:resetLocal="device_to_associate = null"
                    />

                    <v-btn
                        :disabled="device_to_associate === null"
                        :loading="is_loading_confirm_button"
                        block
                        class="validation-button"
                        large
                        @click="autoAssociateDevice"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="is_associate_device_to_admin_dialog_open = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DeviceInput from '@/components/DeviceInput.vue'
import DeviceRepository from '@/repositories/DeviceRepository'

export default {
    name: 'AssociateDeviceToAdminDialogComponent',
    components: { DeviceInput },
    data() {
        return {
            is_associate_device_to_admin_dialog_open: false,
            is_confirm_button_activated: false,
            is_loading_confirm_button: false,
            device_to_associate: null,
        }
    },
    methods: {
        openAssociateDeviceToAdminDialog() {
            this.is_associate_device_to_admin_dialog_open = true
        },
        autoAssociateDevice() {
            this.is_loading_confirm_button = true
            DeviceRepository.putAutoAssociateDevice(this.device_to_associate)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('associate_device.device_associated'),
                    })
                    this.is_associate_device_to_admin_dialog_open = false
                    this.$emit('associated')
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.is_loading_confirm_button = false
                })
        },
        newDeviceSelected(device) {
            this.device_to_associate = device
        },
    },
}
</script>
