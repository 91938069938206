import { render, staticRenderFns } from "./AddEditClientDialogComponent.vue?vue&type=template&id=6b8b29e8&"
import script from "./AddEditClientDialogComponent.vue?vue&type=script&lang=js&"
export * from "./AddEditClientDialogComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports