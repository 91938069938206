<template>
    <div id="manage-asset-image-component">
        <widget-wrapper-component :title="$t('manage_asset_image_component.asset_image')" icon="mdi-image-area" is-already-open>
            <template v-slot:default>
                <v-row class="ma-0 mb-2">
                    <span v-if="asset_images.length === 0" class="font-weight-bold">
                        {{ $t('edit_equipment.no_image_associate') }}
                    </span>
                    <v-spacer />
                    <v-btn class="validation-button" color="primary" small @click="dialog_manage_images_asset = true">
                        {{ $t('edit_equipment.manage_images') }}
                    </v-btn>
                </v-row>

                <v-carousel v-if="asset_images.length > 0" :show-arrows="asset_images.length > 1" height="350">
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn color="validation-button" fab small v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:next="{ on, attrs }">
                        <v-btn color="validation-button" fab small v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-chevron-right</v-icon>
                        </v-btn>
                    </template>

                    <v-carousel-item v-for="image in asset_images" :key="image" :src="image" contain />
                </v-carousel>
            </template>
        </widget-wrapper-component>

        <v-dialog v-model="dialog_manage_images_asset" max-width="600">
            <v-card>
                <v-card-title class="card-title">
                    {{ $t('edit_equipment.image_management_equipment') }}
                </v-card-title>

                <v-card-text class="py-4">
                    <div class="pa-3 mb-8 text-center border-import-file" @drag="prepareUpload">
                        <span>
                            <v-icon>mdi-download</v-icon>
                            {{ $t('edit_equipment.import_image') }}
                        </span>

                        <p class="text-sm text-skin-light">
                            {{ $t('edit_equipment.drop_image_or_click_select_one') }}
                        </p>
                        <input accept="image/png, image/jpeg" type="file" @change="prepareUpload" />
                    </div>

                    <span v-if="asset_images.length === 0" class="font-weight-bold text-h6">
                        {{ $t('edit_equipment.no_image_associate') }}
                    </span>

                    <template v-else>
                        <div v-for="(image, index) in asset_images" :key="index" class="display-flex-content mx-4">
                            <v-checkbox v-model="selected_images" :value="image" />

                            <v-img :src="image" contain max-height="200" />
                        </div>
                    </template>
                </v-card-text>

                <v-container class="px-6">
                    <v-btn
                        :disabled="selected_images.length === 0"
                        :loading="loading_delete_image"
                        block
                        class="mb-4"
                        color="error"
                        large
                        @click.prevent="deleteAssetImages"
                    >
                        {{ $t('global.delete') }}
                    </v-btn>

                    <v-btn
                        :loading="loading_upload_image"
                        block
                        class="validation-button"
                        color="primary"
                        large.
                        @click.prevent="dialog_manage_images_asset = false"
                    >
                        {{ $t('global.close') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AssetRepository from '@/repositories/AssetRepository'
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'

export default {
    name: 'ManageAssetImageComponent',
    components: { WidgetWrapperComponent },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog_manage_images_asset: false,
            loading_upload_image: false,
            loading_delete_image: false,
            selected_images: [],
            asset_images: [],
        }
    },
    created() {
        this.getAssetImages()
    },
    methods: {
        getAssetImages() {
            AssetRepository.getAssetImages(this.asset.id_asset)
                .then((response) => {
                    this.asset_images = response.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.selected_images = []
                    this.loading_upload_image = false
                })
        },

        prepareUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (files.length) {
                if (this.checkImageToUpload(files[0])) {
                    this.uploadPicture(files)
                }
            }
        },

        uploadPicture(files) {
            this.loading_upload_image = true
            let formData = new FormData()
            for (let i = 0; i < files.length; i++) {
                formData.append('files[]', files[i])
            }

            AssetRepository.postAssetImages(this.asset.id_asset, formData)
                .then(() => {
                    this.$store.commit('snackbar/showSnackbar', {
                        color: 'success',
                        text: this.$t('edit_equipment.image_added'),
                    })

                    this.getAssetImages()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_upload_image = false
                })
        },

        deleteAssetImages() {
            let index = 0
            this.loading_delete_image = true

            this.selected_images.forEach((image) => {
                let filename = image.substring(image.lastIndexOf('/') + 1)
                AssetRepository.deleteAssetImage(this.asset.id_asset, filename)
                    .then(() => {
                        index++
                        if (index === this.selected_images.length) {
                            this.$store.commit('snackbar/showSnackbar', {
                                color: 'success',
                                text: this.$t('edit_equipment.image_deleted'),
                            })

                            this.getAssetImages()
                        }
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        if (index === this.selected_images.length) {
                            this.loading_delete_image = false
                        }
                    })
            })
        },
    },
}
</script>
