<template>
    <div id="map-coordinate-component">
        <div v-if="!readonly" class="mb-2">
            <autocomplete-component v-on:change:address="handlePlaceChange" />
            <p class="mb-0 font-12 text-skin-light">
                {{ $t('map_address_component.enter_location_or') }}
                <a @click="geolocateMe()">{{ $t('map_address_component.geolocate_you') }}</a>
            </p>
        </div>

        <div :id="map_uid" class="mb-4 rounded map-container" />
    </div>
</template>

<script>
import { uid } from 'uid'
import AutocompleteComponent from '@/components/site/AutocompleteComponent.vue'
import { mapStyle } from '@/assets/mapStyle.json'

export default {
    name: 'MapCoordinateComponent',
    components: { AutocompleteComponent },
    props: {
        coordinate: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            map_uid: `map-address-${uid()}`,
            map: {},
            position: {},
            bounds_map: {},
            marker: {},
        }
    },
    async mounted() {
        const { Map } = await google.maps.importLibrary('maps')
        this.bounds_map = new google.maps.LatLngBounds()
        this.map = new Map(document.getElementById(this.map_uid), {
            center: { lat: 47, lng: 4 },
            zoom: 5,
            styles: mapStyle,
            mapTypeId: 'satellite',
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite'],
            },
            maxZoom: 20,
        })

        this.position = new google.maps.LatLng({
            lat: 0,
            lng: 0,
        })

        if (this.coordinate.latitude && this.coordinate.longitude) {
            this.position = new google.maps.LatLng({
                lat: this.coordinate.latitude,
                lng: this.coordinate.longitude,
            })
        }

        this.marker = new google.maps.Marker({ map: this.map, position: this.position })
        this.changeMarkerPosition(this.coordinate.latitude && this.coordinate.longitude)

        if (!this.readonly) {
            this.map.addListener('click', (position) => {
                this.position = position.latLng
                this.changeMarkerPosition(false)
            })
        }
    },
    methods: {
        geolocateMe() {
            navigator.geolocation.getCurrentPosition((pos) => {
                this.position = new google.maps.LatLng({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                })
                this.changeMarkerPosition()
            })
        },

        handlePlaceChange(place) {
            this.position = place.geometry.location
            this.changeMarkerPosition()
        },

        changeMarkerPosition(fitBounds = true) {
            this.$emit('change:coordinate', this.position)
            this.marker.setPosition(this.position)

            if (fitBounds) {
                this.bounds_map = new google.maps.LatLngBounds()
                this.bounds_map.extend(this.position)
                this.map.fitBounds(this.bounds_map)
            }
        },
    },
}
</script>
