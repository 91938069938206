const state = () => ({
    show_success_alert: false,
    messages_success_alert: [],
    listen: false,
    listening_device: '',
})

const mutations = {
    showSuccessAlert(state, listen) {
        state.show_success_alert = true
        state.listen = listen
    },

    hideSuccessAlert(state) {
        state.show_success_alert = false
        state.messages_success_alert = []
    },

    hideSuccessAlertButSaveMessages(state) {
        state.show_success_alert = false
    },

    addMessageToSuccessAlert: function (state, value) {
        state.messages_success_alert.push(value)
    },

    setListeningDevice: function (state, value) {
        state.listening_device = value
    },
}

const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
