const state = () => ({
    show_loader: false,
    text_loader: '',
})

const mutations = {
    showLoader(state, payload) {
        state.show_loader = true
        state.text_loader = payload
    },

    hideLoader(state) {
        state.show_loader = false
    },
}

const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
