import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/device_traited_data'

export default {
    putRevokeDeviceTraitedData(id_device_traited_data: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/revoke/${id_device_traited_data}`)
    },
}
