import axios from '@/plugins/axios'
import { AxiosResponse } from 'axios'

const resource = '/api/v1/qr_code'

export default {
    getQrCodeByUid(uid: string): Promise<AxiosResponse<IServerResponse<IQrCode>>> {
        return axios.get(`${resource}/${uid}/detail`)
    },

    postBindQrCode(id_qr_code: number, id_device: number, link: string): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/${id_qr_code}/device/${id_device}`, {
            link: link,
        })
    },
}
