import { render, staticRenderFns } from "./SendSmsPage.vue?vue&type=template&id=4977ea32&scoped=true&"
import script from "./SendSmsPage.vue?vue&type=script&lang=js&"
export * from "./SendSmsPage.vue?vue&type=script&lang=js&"
import style0 from "./SendSmsPage.vue?vue&type=style&index=0&id=4977ea32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4977ea32",
  null
  
)

export default component.exports