<template>
    <div id="manage-stock">
        <v-container>
            <Header :title="$t('in_stock_devices.list_devices_in_stock')" />

            <v-row align="end" justify="end">
                <v-col class="mt-2" cols="12" lg="4">
                    <v-btn block class="validation-button" @click="openAssociateDeviceToAdminDialog">
                        {{ $t('in_stock_devices.self_associate_new_device') }}
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" lg="3" sm="6">
                    <card-value-subtitle-component :subtitle="$t('manage_stock.devices_in_stock')" :value="total_devices_in_stock" />
                </v-col>

                <v-col cols="12" lg="3" sm="6">
                    <card-value-subtitle-component :subtitle="$t('manage_stock.devices_in_return')" :value="total_devices_in_return" />
                </v-col>

                <v-col cols="12" lg="3" sm="6">
                    <card-value-subtitle-component
                        :subtitle="$t('manage_stock.devices_in_transition')"
                        :value="total_devices_in_transition"
                    />
                </v-col>

                <v-col cols="12" lg="3" sm="6">
                    <card-value-subtitle-component
                        :subtitle="$t('manage_stock.devices_in_prototypage')"
                        :value="total_devices_in_prototypage"
                    />
                </v-col>
            </v-row>

            <v-row align="end" justify="end">
                <v-col class="mt-2" cols="12" lg="5">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('manage_stock.research')"
                        clearable
                        elevation="0"
                        flat
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                    />
                </v-col>
            </v-row>

            <v-row class="px-1 mb-6">
                <v-col col="12">
                    <v-toolbar class="pt-2" elevation="0">
                        <v-toolbar-title>
                            {{ $t('manage_stock.devices_by_unique_number') }}

                            <v-spacer />
                        </v-toolbar-title>
                    </v-toolbar>

                    <v-data-table
                        :headers="headers"
                        :items="devices"
                        :loading="loading_device"
                        :server-items-length="total_devices"
                        :sort-by="order_by.key"
                        :sort-desc="order_by.order"
                        v-on:update:items-per-page="handleItemsPerPage"
                        v-on:update:page="handlePagination"
                        v-on:update:sort-by="handleOrderByColumn"
                        v-on:update:sort-desc="handleOrderByDesc"
                    >
                        <template v-slot:item.path_image_device_referentiel="{ item }">
                            <v-img :src="item.path_image_device_referentiel" contain max-height="50" max-width="50" />
                        </template>

                        <template v-slot:item.device_number="{ item }">
                            <a
                                class="text-uppercase text-decoration-underline"
                                @click="
                                    $router.push({
                                        name: 'check_network',
                                        params: {
                                            device_number: item.device_number,
                                        },
                                    })
                                "
                            >
                                {{ item.device_number }}
                            </a>
                        </template>

                        <template v-slot:item.date_last_emission="{ item }">
                            <chips-last-statement
                                v-if="item.date_last_emission"
                                :danger="345600"
                                :date="item.date_last_emission"
                                :warning="172800"
                                class="ml-1"
                            />
                        </template>
                        <template v-slot:item.device_referential="{ item }">
                            {{ item.device_referentiel.name }}
                        </template>

                        <template v-slot:item.network_icon="{ item }">
                            <v-img :src="item.path_image_network" contain max-height="25" position="left" />
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <p class="text-skin-medium text-uppercase font-weight-bold">{{ $t('in_stock_devices.grouped_device_by_model') }}</p>

            <v-row>
                <v-col v-for="kpi in device_referential_kpi" cols="12" md="6">
                    <device-referential-kpi-component :device_referential_kpi="kpi" />
                </v-col>
            </v-row>
        </v-container>

        <associate-device-to-admin-dialog-component ref="associateDeviceToAdminDialog" v-on:associated="updateAllData()" />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import DeviceRepository from '@/repositories/DeviceRepository'
import UserRepository from '@/repositories/UserRepository'
import DeviceInput from '@/components/DeviceInput.vue'
import ChipsLastStatement from '@/components/ChipsLastStatement.vue'
import DeviceReferentialKpiComponent from '@/components/DeviceReferentialKpiComponent.vue'
import AssociateDeviceToAdminDialogComponent from '@/components/AssociateDeviceToAdminDialogComponent.vue'
import CardValueSubtitleComponent from '@/components/CardValueSubtitleComponent.vue'

export default {
    components: {
        CardValueSubtitleComponent,
        AssociateDeviceToAdminDialogComponent,
        DeviceReferentialKpiComponent,
        ChipsLastStatement,
        DeviceInput,
        Header,
    },
    data() {
        return {
            loading_device: false,
            headers: [
                { text: this.$t('manage_stock.id_device'), value: 'id_device' },
                { text: this.$t('manage_stock.icon'), value: 'path_image_device_referentiel', sortable: false },
                { text: this.$t('manage_stock.device_number'), value: 'device_number' },
                { text: this.$t('manage_stock.device_date_last_seen'), value: 'date_last_emission' },
                { text: this.$t('manage_stock.device_referential'), value: 'device_referential' },
                { text: this.$t('manage_stock.network_icon'), value: 'network_icon' },
            ],
            devices: [],
            total_devices: 0,
            search: '',

            total_devices_in_stock: 0,
            total_devices_in_return: 0,
            total_devices_in_transition: 0,
            total_devices_in_prototypage: 0,

            order_by: {
                key: '',
                order: false,
            },
            offset: 0,
            limit: 10,

            device_referential_kpi: [],
        }
    },
    created() {
        this.updateAllData()
    },
    watch: {
        search() {
            if (this.search === null || this.search === '' || this.search.length >= 3) {
                this.getDeviceByIdUserAdminDatatable()
            }
        },
    },
    methods: {
        getNbDevicesByDevicesReferential() {
            DeviceRepository.getNbDevicesByDevicesReferential().then((response) => {
                this.device_referential_kpi = response.data.data
            })
        },
        getDevicesInventoryOfAdminUser() {
            UserRepository.getDevicesInventoryOfAdminUser()
                .then((response) => {
                    response.data.data.forEach((totalDeviceByState) => {
                        switch (parseInt(totalDeviceByState.is_in_stock)) {
                            case -6:
                                this.total_devices_in_transition = totalDeviceByState.total
                                break
                            case -4:
                                this.total_devices_in_return = totalDeviceByState.total
                                break
                            case 1:
                                this.total_devices_in_stock = totalDeviceByState.total
                                break
                            case 2:
                                this.total_devices_in_prototypage = totalDeviceByState.total
                                break
                        }
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
        getDeviceByIdUserAdminDatatable() {
            let orderByClause = ''
            if (typeof this.order_by.key !== 'undefined' && this.order_by.key !== '') {
                orderByClause = this.order_by.key
                if (this.order_by.order === true) {
                    orderByClause += ':desc'
                } else {
                    orderByClause += ':asc'
                }
            }

            this.loading_device = true
            DeviceRepository.getAllDeviceByIdUserAdminAndIsInStockDatatable(
                this.search === null ? '' : this.search,
                orderByClause,
                this.offset,
                this.limit,
            )
                .then((response) => {
                    this.devices = response.data.data.data
                    this.total_devices = response.data.data.total_rows
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_device = false
                })
        },
        handleOrderByColumn(column) {
            // TODO clean this like in the pwa_desk
            if (column === 'date_last_emission') {
                this.order_by.key = 'date_last_seen'
            } else if (column === 'device_number') {
                this.order_by.key = 'numero_device'
            } else if (column === 'device_referential') {
                this.order_by.key = 'dr.nom'
            } else if (column === 'network_icon') {
                this.order_by.key = 'network'
            } else {
                this.order_by.key = column
            }
            this.getDeviceByIdUserAdminDatatable()
        },
        handleOrderByDesc(sort_desc) {
            this.order_by.order = sort_desc
            this.getDeviceByIdUserAdminDatatable()
        },
        handlePagination(page) {
            this.offset = page * this.limit
            this.getDeviceByIdUserAdminDatatable()
        },
        handleItemsPerPage(nbItems) {
            this.limit = nbItems
            this.getDeviceByIdUserAdminDatatable()
        },
        openAssociateDeviceToAdminDialog() {
            this.$refs.associateDeviceToAdminDialog.openAssociateDeviceToAdminDialog()
        },
        updateAllData() {
            this.getDevicesInventoryOfAdminUser()
            this.getDeviceByIdUserAdminDatatable()
            this.getNbDevicesByDevicesReferential()
        },
    },
}
</script>
