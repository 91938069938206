<template>
    <div id="check-network">
        <v-container>
            <Header
                :text="$t('return_devices.return_devices_if_not_return_device_maintenance_will_reallicated')"
                :title="$t('return_devices.return_device')"
            />

            <span class="font-weight-medium font-14">
                <v-icon class="pr-2" color="error">mdi-close-network</v-icon>
                {{ $t('return_devices.returned_device') }}
            </span>

            <device-input
                ref="DeviceInputToReturn"
                :label="$t('return_devices.return_device_number')"
                :type="this.type_to_return"
                class="mt-3"
                v-on:getDevice="getDeviceToReturn"
                v-on:reset="reset"
                v-on:resetLocal="reset"
            />

            <v-expand-transition>
                <div v-if="selected_device_to_return !== ''">
                    <div>
                        <div id="device-to-return">
                            <v-switch v-model="is_return" color="info">
                                <template v-slot:label>
                                    <span class="switch-label">
                                        {{ $t('return_devices.return_device_workshop_maintenance') }}
                                    </span>
                                </template>
                            </v-switch>
                            <div v-if="is_return" id="return">
                                <v-alert icon="mdi-alert-outline" text type="warning">
                                    <div class="alert-text">
                                        {{ $t('return_devices.device_must_returned_fourdata_recommissioning') }}
                                        <br />
                                        {{ $t('return_devices.it_will_no_longer_installable') }}
                                    </div>
                                </v-alert>

                                <v-textarea
                                    v-model="return_reason"
                                    :label="`${$t('return_devices.reason_for_return')}*`"
                                    :rules="empty_rules"
                                    filled
                                >
                                </v-textarea>
                            </div>
                            <div v-else>
                                <v-alert color="info" icon="mdi-alert-circle-outline" text type="info">
                                    <div class="alert-text">
                                        {{
                                            $t('return_devices.device_will_realloacted_to_you_ready_installation_new_client')
                                        }}
                                    </div>
                                </v-alert>
                            </div>
                        </div>

                        <div id="replace-device" class="mt-3">
                            <span class="font-weight-medium font-14">
                                <v-icon class="pr-2" color="success">mdi-check-network</v-icon>
                                {{ $t('return_devices.replacement_device') }}
                            </span>

                            <v-switch v-model="is_replace" color="info" @change="replaceReset()" @click="getContracts">
                                <template v-slot:label>
                                    <span class="switch-label">
                                        {{ $t('return_devices.replace_device_with_new_one') }}
                                    </span>
                                </template>
                            </v-switch>

                            <div v-if="is_replace">
                                <device-input
                                    ref="ReplaceDeviceInput"
                                    :label="$t('return_devices.replacement_device_number')"
                                    :type="this.type_return"
                                    v-on:getDevice="getReplaceDevice"
                                    v-on:reset="replaceReset"
                                    v-on:resetLocal="replaceReset"
                                />

                                <div v-if="selected_replace_device !== ''">
                                    <v-switch v-model="keep_contract" color="info">
                                        <template v-slot:label>
                                            <span class="switch-label">
                                                {{ $t('return_devices.keep_same_contract') }}
                                            </span>
                                        </template>
                                    </v-switch>

                                    <div v-if="!keep_contract" id="keep_contract">
                                        <v-select
                                            v-model="selected_contract"
                                            :disabled="contracts.length === 0"
                                            :items="contracts"
                                            :label="$t('return_devices.select_new_contract')"
                                            :loading="loading_contract"
                                            :menu-props="{ bottom: true, offsetY: true }"
                                            item-value="id_contract_device"
                                            outlined
                                            return-object
                                        >
                                            <template v-slot:item="{ item }">
                                                {{ item.description }} - {{ item.price }} €
                                            </template>

                                            <template v-slot:selection="{ item }">
                                                {{ item.description }} - {{ item.price }} €
                                            </template>
                                        </v-select>

                                        <v-alert icon="mdi-alert-outline" text type="warning">
                                            <div class="alert-text">
                                                {{
                                                    $t('return_devices.new_subscription_will_be_associated_with_replacement_device')
                                                }}
                                            </div>
                                        </v-alert>
                                    </div>

                                    <v-switch v-model="migrate_data" class="mt-0 pb-0 mb-0" color="info">
                                        <template v-slot:label>
                                            <span class="switch-label">
                                                {{ $t('return_devices.migrate_all_data') }}
                                            </span>
                                        </template>
                                    </v-switch>

                                    <v-alert
                                        v-if="!migrate_data"
                                        class="mt-0"
                                        icon="mdi-alert-outline"
                                        text
                                        type="warning"
                                    >
                                        <div class="alert-text">
                                            {{
                                                $t('return_devices.new_subscription_will_be_associated_with_replacement_device')
                                            }}
                                        </div>
                                    </v-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-btn
                        :class="invalid ? 'mt-2' : 'validation-button mt-2'"
                        :disabled="invalid"
                        block
                        large
                        @click="validation_dialog = true"
                    >
                        {{ $t('global.validate') }}
                    </v-btn>
                </div>
            </v-expand-transition>

            <v-dialog v-model="validation_dialog" max-width="600">
                <v-card>
                    <v-card-title class="card-title text-uppercase">
                        {{ $t('return_devices.confirm_return') }}
                    </v-card-title>
                    <v-card-text class="mt-4 pb-0 text-center">
                        <div>
                            <v-row>
                                <v-col :cols="is_replace ? 5 : 12">
                                    <v-icon color="error">mdi-close-network</v-icon>
                                    <p class="confirmation-card-subtitle">
                                        {{ $t('return_devices.returned_device') }}
                                    </p>
                                    <v-chip color="error">
                                        <span class="chip-text">{{ selected_device_to_return }}</span>
                                    </v-chip>
                                </v-col>

                                <v-col v-if="is_replace" cols="2">
                                    <v-icon large>mdi-arrow-right</v-icon>
                                </v-col>

                                <v-col v-if="is_replace" cols="5">
                                    <v-icon color="success">mdi-check-network</v-icon>
                                    <p class="confirmation-card-subtitle">
                                        {{ $t('return_devices.replacement_device') }}
                                    </p>
                                    <v-chip color="success">
                                        <span class="chip-text">{{ selected_replace_device }}</span>
                                    </v-chip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col :cols="is_replace ? 5 : 12">
                                    <div class="list">
                                        <v-row>
                                            <v-col class="pl-0 pr-2" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div v-if="is_return" class="card-text">
                                                    {{ $t('return_devices.must_sent_to_fourdata_for_maintenance') }}
                                                </div>
                                                <div v-else class="card-text">
                                                    {{ $t('return_devices.reallocate_to_my_stock') }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-0">
                                            <v-col class="pl-0 pr-2" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div v-if="is_return" class="card-text">
                                                    {{ $t('return_devices.no_longer_installable') }}
                                                </div>
                                                <div v-else class="card-text">
                                                    {{ $t('return_devices.transfer_stock_ready_installation') }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col v-if="is_replace" cols="2" />
                                <v-col v-if="is_replace" cols="5">
                                    <div class="list">
                                        <v-row>
                                            <v-col class="pl-0 pr-3" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div class="card-text">
                                                    {{ $t('return_devices.switch_operating_mode') }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-0">
                                            <v-col class="pl-0 pr-2" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div class="card-text">
                                                    {{ $t('return_devices.professional_association') }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="!keep_contract" class="mt-0">
                                            <v-col class="pl-0 pr-2" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div class="card-text">
                                                    {{ $t('return_devices.creating_new_contract') }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-0">
                                            <v-col class="pl-0 pr-2" cols="1">
                                                <v-icon class="card-text-chevron">mdi-chevron-right</v-icon>
                                            </v-col>
                                            <v-col class="pl-2 pr-0" cols="9">
                                                <div v-if="migrate_data" class="card-text">
                                                    {{ $t('return_devices.data_migration') }}
                                                </div>
                                                <div v-else class="card-text">
                                                    {{ $t('return_devices.no_data_migration') }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>

                    <v-container class="mt-2">
                        <v-btn block class="validation-button" color="primary" large @click="returnDevice()">
                            {{ $t('global.confirm') }}
                        </v-btn>

                        <v-btn block class="cancel-btn mt-1" large text @click="validation_dialog = false">
                            {{ $t('global.cancel') }}
                        </v-btn>
                    </v-container>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Header from '../../components/Header'
import DeviceInput from '../../components/DeviceInput'
import ContractRepository from '../../repositories/ContractRepository'
import DeviceRepository from '../../repositories/DeviceRepository'

export default {
    name: 'ReturnDevices',
    components: {
        Header,
        DeviceInput
    },
    data() {
        return {
            selected_device_to_return: '',

            is_return: true,
            return_reason: '',
            id_professional: '',

            is_replace: false,
            selected_replace_device: '',

            keep_contract: true,
            contracts: [],
            selected_contract: '',
            loading_contract: false,

            migrate_data: true,

            validation_dialog: false,
            empty_rules: [(v) => !!v || this.$t('return_devices.required_field')],

            professional_name: ''
        }
    },
    computed: {
        invalid: function() {
            let selected_replace_device = true
            let return_reason = true
            let selected_contract = true

            if (this.is_return) {
                return_reason = this.return_reason !== ''
            }

            if (this.is_replace) {
                selected_replace_device = this.selected_replace_device !== ''
            }

            if (!this.keep_contract) {
                selected_contract = this.selected_contract !== ''
            }
            return !(this.selected_device_to_return !== '' && return_reason && selected_replace_device && selected_contract)
        }
    },
    methods: {
        getContracts() {
            this.selected_contract = ''
            if (this.is_replace) {
                ContractRepository.getContracts(this.id_professional, this.selected_device_to_return)
                    .then((success) => {
                        this.contracts = success.data.data
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading_contract = false
                    })
            }
        },

        getDeviceToReturn(value, id_professional, professional_name) {
            this.selected_device_to_return = value
            this.id_professional = id_professional
            this.professional_name = professional_name
        },

        getReplaceDevice(value) {
            this.selected_replace_device = value
        },

        reset() {
            this.selected_device_to_return = ''
            this.is_replace = false
            this.replaceReset()
        },

        replaceReset() {
            this.selected_replace_device = ''
            this.keep_contract = true
            this.selected_contract = ''
        },

        returnDevice() {
            this.validation_dialog = false
            this.$store.commit('loader/showLoader', this.$t('return_devices.transfer_in_progress'))
            DeviceRepository.returnDevice(
                this.selected_device_to_return,
                this.is_return,
                this.return_reason,
                this.is_replace,
                this.selected_replace_device,
                this.selected_contract.id_contract_device,
                this.keep_contract,
                this.migrate_data
            )
                .then(() => {
                    this.$store.commit('successAlert/showSuccessAlert', false)
                    setTimeout(() => {
                        this.$store.commit('successAlert/hideSuccessAlert')
                        this.$router.push('dashboard')
                    }, this.createMessages() * 5000)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.$store.commit('loader/hideLoader')
                })
        },

        createMessages() {
            let count = 0
            if (this.is_return) {
                this.$store.commit('successAlert/addMessageToSuccessAlert', {
                    value_top: this.$t('return_devices.device_been_registered_to_returned', {
                        device_number: this.selected_device_to_return
                    }),
                    value_bottom: this.$t('return_devices.please_return_to_fourdata'),
                    type: 'success'
                })
                count++
            } else {
                this.$store.commit('successAlert/addMessageToSuccessAlert', {
                    value_top: this.$t('return_devices.device_been_assigned_to_you', { device_number: this.selected_device_to_return }),
                    value_bottom: '',
                    type: 'success'
                })
                count++
                this.$store.commit('successAlert/addMessageToSuccessAlert', {
                    value_top: this.$t('return_devices.device_in_stock_ready_installation', {
                        device_number: this.selected_device_to_return
                    }),
                    value_bottom: '',
                    type: 'success'
                })
                count++
            }
            if (this.is_replace) {
                this.$store.commit('successAlert/addMessageToSuccessAlert', {
                    value_top: this.$t('return_devices.device_in_operation_associated_to_professional', {
                        device_number: this.selected_replace_device,
                        professional_name: this.professional_name
                    }),
                    value_bottom: '',
                    type: 'success'
                })
                count++
                if (!this.keep_contract) {
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('return_devices.new_contract_associated_with_device', {
                            contract_description: this.selected_contract.description,
                            contract_price: this.selected_contract.price,
                            device_number: this.selected_replace_device
                        }),
                        value_bottom: '',
                        type: 'success'
                    })
                    count++
                }
                if (this.migrate_data) {
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('return_devices.data_migrate_from_return_device_to_replace_device', {
                            return_device_number: this.selected_device_to_return,
                            replace_device_number: this.selected_replace_device
                        }),
                        value_bottom: '',
                        type: 'success'
                    })
                    count++
                }
            }
            return count
        }
    }
}
</script>

<style>
#return .v-input__slot {
    background-color: white !important;
    border: white !important;
}

.v-text-field__details {
    margin-bottom: 0 !important;
}

.keep_contract .v-input__slot {
    margin: 0 !important;
    padding: 0 !important;
}

.confirmation-card-subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
    font-weight: bold;
    color: #4c545f;
    margin-bottom: 5px !important;
}

.chip-text {
    font-size: 12px;
}

.card-text {
    padding-left: 0 !important;
    font-size: 12px;
    line-height: 14px !important;
    word-break: keep-all !important;
    text-align: start;
    color: #4c545f;
}

.card-text-chevron {
    position: relative;
    top: -6px;
}
</style>
