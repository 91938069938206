<template>
    <div id="custom-icon-component">
        <template v-if="isCustomIcon()">
            <i :class="`${validIcon}`" style="font-size: 1.5em" />
        </template>

        <template v-else>
            <v-icon>{{ validIcon }}</v-icon>
        </template>
    </div>
</template>

<script>
export default {
    name: 'CustomIconComponent',
    props: {
        icon: {
            type: String,
            required: true,
        },
        defaultIcon: {
            type: String,
            required: true,
        },
    },
    methods: {
        isCustomIcon() {
            return this.icon && this.icon.includes('fourdata')
        },
    },
    computed: {
        validIcon() {
            if (this.icon && this.icon !== '' && (this.icon.includes('fourdata') || this.icon.includes('mdi'))) {
                return this.icon
            }
            return this.defaultIcon
        },
    },
}
</script>
