import { VListItem } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"generic-form-component"}},[_vm._l((_vm.form_input_keys),function(key,index){return [_c('div',{key:index,attrs:{"id":key}},[(_vm.getFormInputData(key, 'type') === 'int' || _vm.getFormInputData(key, 'type') === 'float')?[_c(VTextField,{attrs:{"label":_vm.getFormInputData(key, 'label'),"rules":_vm.getFormInputData(key, 'required') ? [function (v) { return !!v || v === 0 || _vm.$t('generic_form_component.required_fields'); }] : [],"filled":"","type":"number"},on:{"change":function($event){return _vm.handleBasicInputChange(key)}},model:{value:(_vm.form_data[key]),callback:function ($$v) {_vm.$set(_vm.form_data, key, $$v)},expression:"form_data[key]"}})]:(_vm.getFormInputData(key, 'type') === 'string')?[_c(VTextField,{attrs:{"label":_vm.getFormInputData(key, 'label'),"rules":_vm.getFormInputData(key, 'required') ? [function (v) { return !!v || _vm.$t('generic_form_component.required_fields'); }] : [],"filled":"","type":"text"},on:{"change":function($event){return _vm.handleBasicInputChange(key)}},model:{value:(_vm.form_data[key]),callback:function ($$v) {_vm.$set(_vm.form_data, key, $$v)},expression:"form_data[key]"}})]:(_vm.getFormInputData(key, 'type') === 'enum')?[_c(VSelect,{attrs:{"items":_vm.parsed_enum[key],"label":_vm.getFormInputData(key, 'label'),"menu-props":{ bottom: true, offsetY: true },"rules":_vm.getFormInputData(key, 'required') ? [function (v) { return !!v || v === 0 || _vm.$t('generic_form_component.required_fields'); }] : [],"filled":"","item-text":"label","item-value":"value"},on:{"change":function (value) { return _vm.handleEnumChange(key, value); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c(VListItem,_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_c('p',{staticClass:"mb-n2"},[_vm._v(_vm._s(item.label))]),(item.subtitle)?_c('span',{staticClass:"caption text--secondary"},[_vm._v(_vm._s(item.subtitle))]):_vm._e()])]},proxy:true}],null,true)},'v-list-item',attrs,false),on))]}}],null,true),model:{value:(_vm.form_data[key]),callback:function ($$v) {_vm.$set(_vm.form_data, key, $$v)},expression:"form_data[key]"}})]:(_vm.getFormInputData(key, 'type') === 'child_enum')?[_c(VSelect,{attrs:{"items":_vm.parseChildEnum(
                            _vm.parsed_enum[key].find(function (item) { return item.value === parseInt(_vm.form_data[_vm.getFormInputData(key, 'parent_enum')]); })
                        ),"label":_vm.getFormInputData(key, 'label'),"menu-props":{ bottom: true, offsetY: true },"rules":_vm.getFormInputData(key, 'required') ? [function (v) { return !!v || v === 0 || _vm.$t('generic_form_component.required_fields'); }] : [],"filled":"","item-text":"label","item-value":"value"},on:{"change":function (value) { return _vm.handleEnumChange(key, value); }},model:{value:(_vm.form_data[key]),callback:function ($$v) {_vm.$set(_vm.form_data, key, $$v)},expression:"form_data[key]"}})]:_vm._e()],2)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }