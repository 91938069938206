import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-card"}},[_c(VCard,{staticClass:"rounded-xl dashboard-card shadow-lg",attrs:{"min-height":_vm.size === 'l' ? 150 : ''},on:{"click":_vm.redirection}},[(_vm.size === 'l')?[_c(VCardText,[_c('div',[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VIcon,{attrs:{"color":"blue","large":""}},[_vm._v(_vm._s(_vm.icon))])],1),(_vm.append_inner)?_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c(VChip,{staticClass:"white--text",attrs:{"color":_vm.append_inner_color}},[_vm._v(_vm._s(_vm.append_inner))])],1):_vm._e()],1)],1),_c('div',{staticClass:"pl-2"},[_c('p',{staticClass:"font-20 font-weight-medium mb-0"},[_vm._v(_vm._s(_vm.title))]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))])])])]:[_c('div',{staticClass:"px-2 py-4"},[_c('div',[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":_vm.append_inner ? 9 : 12}},[_c('div',{staticClass:"d-flex align-start gap-2"},[_c(VIcon,{staticClass:"pt-1",attrs:{"color":"blue","small":""}},[_vm._v(_vm._s(_vm.icon))]),_c('div',[_c('p',{staticClass:"font-14 font-weight-medium mb-0"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"text mb-0"},[_vm._v(_vm._s(_vm.text))])])],1)]),(_vm.append_inner)?_c(VCol,{staticClass:"d-flex justify-end py-0",attrs:{"cols":"3"}},[_c(VChip,{staticClass:"white--text",attrs:{"color":_vm.append_inner_color}},[_vm._v(_vm._s(_vm.append_inner))])],1):_vm._e()],1)],1)])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }